<style lang="scss">
@import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>
<template>
  <v-card>
    <v-card-title>
      <span class="headline"><v-icon>mdi-lead-pencil</v-icon>伝票日付変更</span>
    </v-card-title>
    <v-card-text>
      <v-form ref="transferForm" v-model="validTransferForm" lazy-validation>
        <v-container>
          <v-divider class="my-3"></v-divider>
          <v-row dense>
            <v-col cols="12" sm="5">
              <dp-date-picker type="date" label="伝票日付" v-model="updateModel.slipDate" filled dense></dp-date-picker>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
      <v-container style="max-width: inherit">
        <v-col style="height: 500px">
          <tooltip-icon-button
            :disabled="selectionRows.length == 0"
            icon="mdi-arrow-down-bold-outline"
            @click="onReflection"
            >一括反映</tooltip-icon-button
          >
          <ag-grid-vue
            :gridOptions="gridOptions"
            class="ag-theme-alpine"
            style="height: 100%"
            @grid-ready="onGridReady"
            @selection-changed="onSelectionChanged"
            @cell-value-changed="onCellValueChanged"
            @cell-editing-started="onCellEditingStarted"
          ></ag-grid-vue>
        </v-col>
      </v-container>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn color="secondary" @click="onCancelClick">キャンセル</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="onSlipDateSubmit">更新</v-btn>
    </v-card-actions>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
    </v-overlay>
    <error-grid-dialog
      ref="updateErrorGrid"
      width="80%"
      height="80%"
      title="伝票日付更新"
      :columns="errorColmuns"
    ></error-grid-dialog>
  </v-card>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import { statuses as ApiStatus } from "../../libs/api-client";
import { AG_GRID_LOCALE_JA } from "../../models/ag-grid/locales";
import {
  NumericColumn,
  PercentColumn,
  DateColumn,
  CheckmarkColumn,
  EditableColumn,
  EditableDateColumn,
  EditableCustomDateColumn,
} from "../../models/ag-grid/columnTypes";
import { BooleanFilter, SelectionFilter } from "../../components/ag-grid/filters";
import ErrorGridDialog from "../../components/common/ErrorGridDialog.vue";
import Validation from "../../libs/validation";
import StockStatus from "../../consts/amazonStatus/BaseStatuses";
import { DateCellEditor, NumericCellEditor, CustomDateCellEditor } from "../../components/ag-grid/cellEditors";
import OrderTypes from "../../consts/OrderTypes";
import ProductTypes from "../../consts/ProductTypes";
import TooltipIconButton from "../common/TooltipIconButton.vue";
import CafereoProductStatus from "../../consts/productStatus/CafereoProductStatus";
import "ag-grid-autocomplete-editor/dist/main.css";
import { DateValueFormatter } from "../../models/ag-grid/valueFormatters";

export default {
  name: "UpdateSlipDate",
  props: ["inputModel"],
  components: {
    AgGridVue,
    TooltipIconButton,
    ErrorGridDialog,
    /* eslint-disable vue/no-unused-components */
    CustomDateCellEditor,
  },
  data() {
    return {
      gridApi: null,
      columnApi: null,
      validTransferForm: null,
      rules: {
        required: Validation.required,
        maxLength: Validation.maxLength,
        isNumber: Validation.isNumber,
        isDate: Validation.isDate,
        confirmNoRule: (value) => this.confirmNoRules(value),
      },
      StockList: StockStatus.all(),
      selectedPageSize: 10,
      gridOptions: {
        columnTypes: {
          dpDateColumn: DateColumn,
          dpPercentColumn: PercentColumn,
          dpCheckmarkColumn: CheckmarkColumn,
          dpNumericColumn: NumericColumn,
          dpEditableDateColumn: EditableDateColumn,
          dpEditableColumn: EditableColumn,
          dpEditableCustomDateColumn: EditableCustomDateColumn,
        },
        frameworkComponents: {
          dpBooleanFilter: BooleanFilter,
          dpDateCellEditor: DateCellEditor,
          dpNumericCellEditor: NumericCellEditor,
          dpCellDatePicker: CustomDateCellEditor,
          dpSelectionFilter: SelectionFilter,
        },
        defaultColDef: {
          resizable: true,
          sortable: true,
          filter: "agTextColumnFilter",
          filterParams: {
            newRowsAction: "keep",
          },
        },
        columnDefs: [
          {
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            checkboxSelection: true,
            filter: false,
            resizable: false,
            sortable: false,
            pinned: "left",
          },
          { headerName: "受注ID", field: "orderId", pinned: "left" },
          { headerName: "指示書NO", field: "directionNumber" },
          { headerName: "発注日", field: "orderDate", type: "dpDateColumn", filter: "agDateColumnFilter" },
          {
            headerName: "伝票日付",
            field: "slipDate",
            type: "dpEditableCustomDateColumn",
            cellEditorFramework: "CustomDateCellEditor",
            valueFormatter: DateValueFormatter("YYYY/MM/DD"),
            cellStyle: (params) => {
              let ret = { backgroundColor: "#FFFFCC" };
              if (params.data.updateSlipDate) {
                ret.backgroundColor = "#CCFFCC";
              }
              return ret;
            },
          },
          { headerName: "伝票番号", field: "slipNumber" },
          {
            headerName: "受注区分",
            field: "orderType",
            filter: "dpSelectionFilter",
            filterParams: { options: OrderTypes.all() },
            valueGetter: (params) => OrderTypes.of(params.data.orderType),
          },
          {
            headerName: "商品区分",
            field: "productType",
            filter: "dpSelectionFilter",
            filterParams: { options: ProductTypes.all() },
            valueGetter: (params) => ProductTypes.of(params.data.productType),
          },
          {
            headerName: "商品ステータス",
            field: "productStatus",
            filter: "dpSelectionFilter",
            filterParams: { options: CafereoProductStatus.all() },
            valueGetter: (params) => CafereoProductStatus.of(params.data.productStatus),
          },
          { headerName: "JANCODE", field: "janCode" },
          { headerName: "タイトル", field: "title" },
          { headerName: "商品名", field: "productName" },
          { headerName: "出荷数", field: "shipQuantity", type: "dpNumericColumn" },
          { headerName: "上代", field: "retailPrice", type: "dpNumericColumn" },
          {
            headerName: "卸掛率",
            field: "wholesaleRate",
            type: "dpPercentColumn",
          },
          {
            headerName: "卸単価",
            field: "unitPrice",
            type: "dpNumericColumn",
          },
          { headerName: "単価合計金額", field: "unitPriceTotal", type: "dpNumericColumn" },
          { headerName: "メーカー名", field: "supplierName" },
          {
            headerName: "仕入掛率",
            field: "purchaseRate",
            type: "dpPercentColumn",
          },
          {
            headerName: "仕入単価",
            field: "purchasePrice",
            type: "dpNumericColumn",
          },
          { headerName: "粗利単価", field: "grossProfit", type: "dpNumericColumn" },
          { headerName: "粗利合計金額", field: "grossProfitTotal", type: "dpNumericColumn" },
          { headerName: "取引先名", field: "customerName" },
          { headerName: "配送CD", field: "deliveryCode" },
          { headerName: "配送先住所", field: "deliveryAddress" },
          { headerName: "届先法人名", field: "deliveryName1" },
          { headerName: " 届先名称", field: "deliveryName2" },
          { headerName: "直送依頼", field: "directDeliveryRequestFlg", type: "dpCheckmarkColumn" },
          { headerName: "伝票備考", field: "slipRemarks" },
          { headerName: "担当チーム", field: "salesTeam" },
          { headerName: "営業担当", field: "salesStaff" },
          { headerName: "アシスタント", field: "salesAssistant" },
          { headerName: "社内備考", field: "cafereoRemarks" },
          { headerName: "法人CD", field: "corporateCode", hide: true },
          { headerName: "案件番号", field: "propositionNumber", hide: true },
          { headerName: "承認者", field: "approvalUser", hide: true },
          { headerName: "承認日時", field: "approvalDatetime", hide: true },
          { headerName: "掛率訂正承認者", field: "rateCorrectionAuthorizer", hide: true },
          { headerName: "掛率訂正承認日時", field: "rateCorrectionApprovalDate", hide: true },
          { headerName: "販売店承認者", field: "salesAuthorizer", hide: true },
          { headerName: "販売店承認日時", field: "salesApprovalDate", hide: true },
          { headerName: "納品予定日", field: "deliveryPlanDate", hide: true },
          { headerName: "登録者", field: "createUser", hide: true },
          { headerName: "登録日時", field: "createDatetime", hide: true },
          { headerName: "更新者", field: "updateUser", hide: true },
          { headerName: "更新日時", field: "updateDatetime", hide: true },
          { headerName: "備考", field: "remarks", hide: true },
          { headerName: "発注ID", field: "purchaseId", hide: true },
          {
            headerName: "受注ステータス",
            field: "orderStatus",
            hide: true,
          },
          { headerName: "フォーキャスト受注", field: "forecastOrderFlg", hide: true },
        ],
        rowSelection: "multiple",
        alwaysShowHorizontalScroll: true,
        suppressCellSelection: true,
        pagination: true,
        paginationPageSize: 10,
        enableCellTextSelection: true,
        localeText: AG_GRID_LOCALE_JA,
        suppressColumnVirtualisation: true,
        getRowNodeId: (data) => data.orderId,
      },
      selectionRows: [],
      updateModel: {
        slipDate: null,
      },
      errorColmuns: [
        { headerName: "受注ID", field: "orderId" },
        {
          headerName: "エラー内容",
          field: "errorMessage",
          wrapText: true,
          autoHeight: true,
          cellRenderer: function (param) {
            return param.data.errorMessage.join("<br>");
          },
        },
      ],
      errorRows: [],
      updateSuccessRecords: [],
    };
  },
  beforeMount() {
    this.gridOptions.paginationPageSize = this.globalPageSize;
  },
  watch: {
    inputModel(inputModel) {
      this.init(inputModel);
    },
    globalPageSize(size) {
      this.gridApi.paginationSetPageSize(size);
      this.handleResize();
    },
  },
  computed: {
    isLoading() {
      return this.$store.getters["ui/isLoading"];
    },
  },
  methods: {
    async init(inputModel) {
      this.initModel(inputModel);
    },
    initModel(inputModel) {
      this.updateSuccessRecords = [];
      this.updateModel = {
        slipDate: null,
      };
      this.gridApi.setRowData(JSON.parse(JSON.stringify(inputModel)));
    },
    reset() {
      this.init(this.inputModel);
    },
    onCancelClick() {
      this.$emit("update", this.updateSuccessRecords.length != 0);
    },
    onReflection() {
      if (this.validate()) {
        let updateRows = [];
        this.selectionRows.forEach((selectionRow) => {
          selectionRow.slipDate = this.updateModel.slipDate ? this.updateModel.slipDate : selectionRow.slipDate;
          selectionRow.updateSlipDate = true;
          updateRows.push(selectionRow);
        });
        // レコードを更新
        this.gridApi.applyTransaction({ update: updateRows });
        this.$refs.transferForm.resetValidation();
      }
    },
    async onSlipDateSubmit() {
      try {
        let condition = [];
        this.gridApi.forEachNode((node) => {
          let row = node.data;
          condition.push({
            orderId: row.orderId,
            slipDate: row.slipDate,
            updateDatetime: row.updateDatetime,
          });
        });
        var isValid = this.validateRow(condition);
        // エラー表示
        if (this.errorRows.length > 0) {
          this.$refs.updateErrorGrid.open({ records: this.errorRows });
        }

        if (isValid) {
          this.loadingOn();
          const response = await this.$store.dispatch("order/updateSlipDate", { orderList: condition });
          let error = response.data?.header;
          console.log("UpdateSlipDate::onSlipDateSubmit", response);
          let updateRows = [];
          this.errorRows = [];
          switch (error.resultCode) {
            case ApiStatus.consts.SUCCESS:
            case ApiStatus.consts.BUSINESS_ERROR:
            case ApiStatus.consts.ALREADY_CHANGED:
              // エラーメッセージ格納
              if (error.messages) {
                Object.keys(error.messages).forEach((key) => {
                  this.errorRows.push({
                    orderId: key,
                    errorMessage: error.messages[key],
                  });
                });
              }
              // 一覧部の更新分のレコード設定
              this.gridApi.forEachNode((node) => {
                var row = node.data;
                this.errorRows.forEach((errorRow) => {
                  if (errorRow.orderId != row.orderId) {
                    this.updateSuccessRecords.push(row);
                    updateRows.push({ orderId: row.orderId });
                  }
                });
              });
              // エラー表示
              if (this.errorRows.length > 0) {
                // 確認ダイアログから更新した分を削除(エラーがある場合のみ)
                this.gridApi.applyTransaction({ remove: updateRows });
                this.$refs.updateErrorGrid.open({ records: this.errorRows });
                this.$dialog.notify.error(`伝票日付の更新処理に失敗したデータが存在します。ご確認ください。`, {
                  timeout: 2300,
                });
              } else {
                this.$dialog.notify.info("伝票日付を更新しました", { timeout: 2300 });
                this.$refs.updateErrorGrid.close();
                this.$emit("update", true);
                this.reset();
              }
              break;
            default:
              this.redirectError();
              break;
          }
        }
      } catch (error) {
        console.error("UpdateSlipDate::onSlipDateSubmit", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
    validate() {
      const isValid = this.$refs.transferForm.validate();
      if (!isValid) {
        this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
      }
      return isValid;
    },
    confirmNoRules(value) {
      if (value == null) return true;
      if (isNaN(value)) return "数字を入力してください";
      return true;
    },
    onSelectionChanged() {
      this.selectionRows = this.gridApi.getSelectedRows();
    },
    async onCellValueChanged(params) {
      console.log(params);
      // セルの色を変えるため項目更新をチェック
      if (params.colDef.field == "slipDate") {
        params.data.updateSlipDate = true;
      }
      let rows = [];
      rows.push(params.node);
      this.gridApi.refreshCells({ force: true, rowNodes: rows });
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
      this.init(this.inputModel);
      var allColumnIds = [];
      this.columnApi.getAllColumns().forEach(function (column) {
        allColumnIds.push(column.colId);
      });
      this.columnApi.autoSizeColumns(allColumnIds);
    },
    setValidMessage(check, culumnName, messages) {
      if (!(check === true)) messages.push(culumnName + "は" + check);
    },
    validateRow(rows) {
      var ret = true;

      rows.forEach((row) => {
        console.log("row:", row);
        var messages = [];
        this.setValidMessage(this.rules.isDate(row.slipDate), "伝票日付", messages);
        if (messages.length > 0) {
          this.errorRows.push({
            orderId: row.orderId,
            errorMessage: messages,
          });
          ret = false;
        }
      });
      return ret;
    },
    onCellEditingStarted(event) {
      console.log("cellEditingStarted " + event.column.colId);
      const popupTop = event.api.getCellEditorInstances()[0].getGui().style.top;
      event.api.getCellEditorInstances()[0].getGui().style.top = parseInt(popupTop) - 40 + "px";
    },
  },
};
</script>
