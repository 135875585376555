<template>
  <v-container class="grey lighten-5" v-if="details">
    <v-row no-gutters>
      <v-col v-for="detail in details" :key="detail.name" cols="12" xs="12">
        <detail-card v-if="detail.name" :name="detail.name" :value="detail.value"></detail-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DetailCard from "../common/DetailCard.vue";
import { getDisplayDetailsValue } from "../../models/ag-grid/helpers";
import { statuses as ApiStatus } from "../../libs/api-client";

export default {
  name: "TrackingInformationDetails",
  props: ["orderId"],
  components: {
    DetailCard,
  },
  data() {
    return {
      columnDefs: null,
      details: null,
    };
  },
  beforeMount() {
    this.columnDefs = [
      { headerName: "受注ID", field: "orderId" },
      {
        headerName: "送状番号",
        field: "invoiceNumber",
      },
      { headerName: "送状発行日", field: "invoiceIssueDate", type: "dpDateColumn" },
      { headerName: "得意先名称", field: "corporationName" },
      { headerName: "社店CD", field: "customerCode" },
      { headerName: "取引先名称", field: "customerName" },
      { headerName: "配送CD", field: "deliveryCode" },
      { headerName: "届先法人名", field: "deliveryName1" },
      { headerName: "住所", field: "deliveryAddress" },
      { headerName: " 届先名称", field: "deliveryName2" },
      { headerName: "発生元番号", field: "originNumber" },
    ];
    if (this.isVendorUser)
      this.columnDefs = [
        { headerName: "発注ID", field: "orderId" },
        {
          headerName: "送状番号",
          field: "invoiceNumber",
        },
        { headerName: "送状発行日", field: "invoiceIssueDate", type: "dpDateColumn" },
        { headerName: "得意先名称", field: "corporationName" },
        { headerName: "社店CD", field: "customerCode" },
        { headerName: "取引先名称", field: "customerName" },
        { headerName: "配送CD", field: "deliveryCode" },
        { headerName: "届先法人名", field: "deliveryName1" },
        { headerName: "住所", field: "deliveryAddress" },
        { headerName: " 届先名称", field: "deliveryName2" },
        { headerName: "発生元番号", field: "originNumber" },
      ];
  },
  watch: {
    orderId(orderId) {
      this.init(orderId);
    },
  },
  mounted() {
    this.init(this.orderId);
  },
  methods: {
    async init(orderId) {
      try {
        this.loadingOn();
        let searchModel = {
          orderId: orderId,
        };
        const response = await this.$store.dispatch("order/trackingInfo", searchModel);
        if (ApiStatus.isSystemError(response.data?.header)) {
          return this.redirectError();
        }
        if (response.data.contents && response.data.contents.tracking?.invoice) {
          let trackingData = response.data.contents.tracking.invoice;
          trackingData["orderId"] = this.orderId;
          trackingData.invoiceNumber = trackingData.invoiceNumber.join(",");
          this.details = getDisplayDetailsValue(this.columnDefs, trackingData);
        } else {
          this.details = {};
        }
      } catch (error) {
        console.error("TrackingInformationDetails::search", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
  },
};
</script>
