<template>
  <v-container fluid style="height: 100%">
    <v-row no-gutters>
      <v-col>
        <v-btn icon v-if="infoMaximum" @click="onInfoMaximum" class="float-right"
          ><v-icon>mdi-window-minimize</v-icon></v-btn
        >
        <v-btn icon v-if="!infoMaximum" @click="onInfoMaximum" class="float-right"
          ><v-icon>mdi-window-maximize</v-icon></v-btn
        >
        <v-tabs v-model="tab" centered grow class="mt-2 mb-2" hide-slider>
          <v-tab>{{ isCafereoUser ? "受注詳細" : "発注詳細" }}</v-tab>
          <v-tab>履歴</v-tab>
          <v-tab>追跡情報</v-tab>
        </v-tabs>
        <v-divider></v-divider>
        <v-tabs-items v-model="tab" id="tab-items">
          <v-tab-item>
            <order-details :style="detaleStyle" :details="details"></order-details>
          </v-tab-item>
          <v-tab-item>
            <order-history
              v-if="details && details.length"
              :orderId="orderId"
              :isAchievementMode="isAchievementMode"
            ></order-history>
          </v-tab-item>
          <v-tab-item>
            <tracking-information-details
              v-if="details && details.length"
              :orderId="orderId"
              :style="detaleStyle"
            ></tracking-information-details>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import OrderDetails from "./OrderDetails.vue";
import OrderHistory from "./OrderHistory.vue";
import TrackingInformationDetails from "./TrackingInformationDetails.vue";
export default {
  name: "OrderInfos",
  props: ["details", "orderId", "isAchievementMode"],
  data: () => ({
    tab: null,
    items: ["1", "2"],
    infoMaximum: false,
    detaleStyle: {
      height: window.innerHeight - 260 + "px",
      overflow: "scroll",
    },
  }),
  components: {
    OrderDetails,
    OrderHistory,
    TrackingInformationDetails,
  },
  methods: {
    onInfoMaximum: function () {
      this.infoMaximum = !this.infoMaximum;
      this.$emit("infoMaximum-event");
    },
    handleResize: function () {
      this.detaleStyle.height = window.innerHeight - 260 + "px";
    },
  },
  mounted: function () {
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy: function () {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>
