<template>
  <v-card>
    <v-card-title>
      <span class="headline"><v-icon>mdi-set-split</v-icon>{{ title }}</span>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text style="height: 600px">
      <v-card-subtitle class="text-subtitle-1">分割元{{ isCafereoUser ? "受注" : "発注" }}</v-card-subtitle>
      <v-form>
        <v-container class="pa-0">
          <v-row dense>
            <v-col cols="12" sm="4">
              <v-text-field
                :label="isCafereoUser ? '受注ID' : '発注ID'"
                filled
                dense
                hide-details
                readonly
                :value="orderModel.orderId"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                label="JANCODE"
                filled
                dense
                hide-details
                readonly
                :value="orderModel.janCode"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                label="タイトル"
                filled
                dense
                hide-details
                readonly
                :value="orderModel.title"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="8">
              <v-text-field
                label="商品名"
                filled
                dense
                hide-details
                readonly
                :value="orderModel.productName"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                label="発注数量"
                filled
                dense
                hide-details
                readonly
                :value="orderModel.orderQuantity | comma"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
      <v-card-subtitle class="mt-3 text-subtitle-1">
        分割後{{ isCafereoUser ? "受注" : "発注" }}一覧
        <tooltip-icon-button icon="mdi-playlist-plus" @click="onAddClick">追加</tooltip-icon-button>
        <tooltip-icon-button icon="mdi-trash-can-outline" :disabled="selectionRows.length === 0" @click="onRemoveClick"
          >削除</tooltip-icon-button
        >
      </v-card-subtitle>
      <v-col style="height: 340px">
        <ag-grid-vue
          class="ag-theme-alpine"
          style="height: 100%"
          :defaultColDef="defaultColDef"
          :columnDefs="columnDefs"
          :rowData="orderRecords"
          :alwaysShowHorizontalScroll="true"
          :pagination="true"
          :singleClickEdit="true"
          :localeText="localeText"
          @cellValueChanged="onCellValueChanged"
          :components="component"
          @grid-ready="onGridReady"
          @selection-changed="onSelectionChanged"
          :getRowNodeId="(data) => data.rowId"
        >
        </ag-grid-vue>
      </v-col>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn color="secondary" @click="onCancel">キャンセル</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="onSubmit">登録</v-btn>
    </v-card-actions>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
    </v-overlay>
    <error-grid-dialog
      ref="updateErrorGrid"
      width="80%"
      height="80%"
      icon=""
      :title="title"
      btnSubmit="登録"
      :columns="errorColmuns"
    ></error-grid-dialog>
  </v-card>
</template>

<style lang="scss">
@import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>

<script>
import { AgGridVue } from "ag-grid-vue";
import { AG_GRID_LOCALE_JA } from "../../models/ag-grid/locales";
import { NumberValueFormatter } from "../../models/ag-grid/valueFormatters";
import { comma } from "../../filter/NumberFilter";
import TooltipIconButton from "../common/TooltipIconButton.vue";
import { AutocompleteSelectCellEditor } from "ag-grid-autocomplete-editor";
import "ag-grid-autocomplete-editor/dist/main.css";
import { statuses as ApiStatus } from "../../libs/api-client";
import Validation from "../../libs/validation";
import ErrorGridDialog from "../../components/common/ErrorGridDialog.vue";

export default {
  name: "OrderSplit",
  props: {
    orderModel: { type: Object, default: () => ({}) },
    assign: { type: Boolean, default: false },
  },
  components: {
    AgGridVue,
    TooltipIconButton,
    ErrorGridDialog,
  },
  filters: {
    comma,
  },
  data: function () {
    return {
      component: null,
      customers: [],
      customerCd: null,
      customerMap: {},
      deliverys: [],
      customerdata: [],
      defaultColDef: {
        resizable: true,
        sortable: false,
        filter: false,
        editable: true,
      },
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      rowSelection: "multiple",
      suppressRowClickSelection: true,
      singleClickEdit: true,
      pagination: false,
      localeText: AG_GRID_LOCALE_JA,
      domLayout: "autoHeight",
      selectionRows: [],
      orderRecords: [],
      rules: {
        required: Validation.required,
        isNumber: Validation.isNumber,
        isMinNumber: Validation.isMinNumber,
      },
      errorColmuns: [
        { headerName: "行番号", field: "rowNumber" },
        {
          headerName: "エラー内容",
          field: "errorMessage",
          wrapText: true,
          autoHeight: true,
          cellRenderer: function (param) {
            return param.data.errorMessage.join("<br>");
          },
        },
      ],
      rowId: 1,
    };
  },
  watch: {
    async orderModel(model) {
      if (model) {
        await this.onInit(model);
      }
    },
  },
  computed: {
    isLoading() {
      return this.$store.getters["ui/isLoading"];
    },
    title() {
      var title = "";
      title += this.isCafereoUser ? "受注" : "発注";
      title += this.assign ? "割当" : "分割";
      return title;
    },
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;

      var allColumnIds = [];
      this.columnApi.getAllColumns().forEach(function (column) {
        allColumnIds.push(column.colId);
      });
    },
    async onInit(model) {
      try {
        this.customers = [];
        this.customerMap = {};
        const response = await this.$store.dispatch("order/searchOrderAcceptedCustomer", {
          CorporationCode: model.corporateCode,
          ProductCode: model.productCode,
        });
        if (!ApiStatus.isSystemError(response.data?.header)) {
          var customers = response.data.contents.customers;
          for (let i = 0; i < customers.length; i++) {
            const customer = customers[i];
            const customerInfo = {
              value: customer.customerCode,
              label: customer.customerName,
            };
            this.customers.push(customerInfo);
          }
        }
      } catch (error) {
        console.error("OrderSplit::onInit", error);
        this.apiRequestError(error);
      }
      this.gridApi.setRowData([]);
      this.onAddClick();
    },
    onAddClick() {
      let rowData = [];
      this.gridApi.forEachNode((node) => rowData.push(node.data));
      const newRows = [
        { customerCode: "", deliveryCode: "", quantity: 0, rowNumber: rowData.length + 1, rowId: this.rowId },
      ];
      this.gridApi.applyTransaction({ add: newRows });
      this.rowId++;
    },
    onRemoveClick() {
      this.gridApi.applyTransaction({ remove: this.selectionRows });
      let rowData = [];
      this.gridApi.forEachNode((record, index) => {
        record.data.rowNumber = index + 1;
        rowData.push(record.data);
      });
      this.gridApi.applyTransaction({ update: rowData });
    },
    onCancel() {
      this.component = null;
      this.$emit("cancel");
    },
    async onSubmit() {
      this.gridApi.stopEditing(false);
      var requestRecords = [];
      this.errorRows = [];
      var isValid = true;
      var splitCount = 0;
      let dataTitle = this.assign ? "割当" : "分割";
      let orderTitle = this.isCafereoUser ? "受注" : "発注";
      let isRecord = false;
      this.gridApi.forEachNode((record) => {
        isRecord = true;
        var error = this.validateRow(record.data);
        if (error != true) {
          this.errorRows.push({
            rowNumber: record.data.rowNumber,
            errorMessage: error,
          });
          isValid = false;
        } else {
          requestRecords.push(this.requestFormat(record.data));
          splitCount += Number(record.data.quantity);
        }
      });
      if (!isRecord) {
        this.errorRows.push({
          rowNumber: null,
          errorMessage: [`${dataTitle}情報を追加してください`],
        });
        isValid = false;
      }
      if (splitCount > this.orderModel.orderQuantity) {
        this.errorRows.push({
          rowNumber: null,
          errorMessage: [`${dataTitle}数の合計は${orderTitle}数以下で設定してください`],
        });
        isValid = false;
      }

      if (isValid) {
        try {
          this.loadingOn();
          const updateModel = {
            orderId: this.orderModel.orderId,
            orders: requestRecords,
            lastUpdateDatetime: this.orderModel.updateDatetime,
          };
          const url = this.assign ? "forcast/assign" : "order/split";
          const response = await this.$store.dispatch(url, updateModel);
          let error = response.data?.header;
          this.errorRows = [];
          switch (error.resultCode) {
            case ApiStatus.consts.SUCCESS:
              var messsage = this.assign ? `${orderTitle}を割当ました` : "受注を分割しました";
              this.$dialog.notify.info(`${messsage} (${requestRecords.length}件)`, { timeout: 2300 });
              this.$emit("created");
              this.reset();
              break;
            case ApiStatus.consts.BUSINESS_ERROR:
            case ApiStatus.consts.ALREADY_CHANGED:
              var message = [];
              if (response.data?.header.messages) {
                Object.keys(response.data?.header.messages).forEach((key) => {
                  message.push(response.data?.header.messages[key]);
                });
              }
              this.$dialog.warning({
                title: this.title,
                text: message.join("<br>"),
                actions: ["OK"],
              });
              break;
            default:
              this.redirectError();
              break;
          }
        } catch (error) {
          console.error("OrderSplit::onSubmit", error);
          this.apiRequestError(error);
        } finally {
          this.loadingOff();
        }
      } else {
        this.$refs.updateErrorGrid.open({ records: this.errorRows });
        this.$dialog.notify.error(`${dataTitle}データに入力エラーが存在します。ご確認ください。`, {
          timeout: 2300,
        });
      }
    },
    async onCellValueChanged(event) {
      if (event.column.colId === "customerCode" && event.oldValue !== event.newValue) {
        if (event.value) {
          await this.setDeliveryList(event.newValue.value);
          event.node.setDataValue("deliveryCode", null);
        } else {
          event.node.setDataValue("deliveryCode", null);
        }
      }
    },
    validateRow(row) {
      var ret = true;
      var messages = [];
      // 取引先名
      this.setValidMessage(this.rules.required(row.customerCode?.value), "取引先名", messages);
      // 配送先
      this.setValidMessage(this.rules.required(row.deliveryCode?.value), "配送先", messages);
      // 数量
      this.setValidMessage(this.rules.required(row.quantity), "数量", messages);
      this.setValidMessage(this.rules.isNumber(row.quantity), "数量", messages);
      this.setValidMessage(this.rules.isMinNumber(1)(row.quantity), "数量", messages);

      if (messages.length > 0) ret = messages;
      return ret;
    },
    setValidMessage(check, culumnName, messages) {
      if (!(check === true)) messages.push(culumnName + "は" + check);
    },
    requestFormat(row) {
      return {
        customerCode: row.customerCode?.value,
        deliveryCode: row.deliveryCode?.value,
        orderQuantity: Number(row.quantity),
      };
    },
    async setDeliveryList(customerCode) {
      try {
        let deliveryIndex = this.deliverys.findIndex((delivery) => delivery.customerCode == customerCode);
        let nowDeliverys = [];
        const response = await this.$store.dispatch("deliveryDestination/searchDelivery", {
          groupCode: customerCode,
        });
        if (!ApiStatus.isSystemError(response.data?.header)) {
          var deliverys = response.data.contents.deliveries;
          for (let i = 0; i < deliverys.length; i++) {
            const delivery = deliverys[i];
            const deliveryInfo = {
              value: delivery.deliveryCode,
              label: delivery.delivery2,
            };
            nowDeliverys.push(deliveryInfo);
          }
        }

        if (deliveryIndex >= 0) {
          this.deliverys[deliveryIndex].deliverys = nowDeliverys;
        } else {
          this.deliverys.push({ customerCode: customerCode, deliverys: nowDeliverys });
        }
      } catch (error) {
        console.error("OrderSplit::onCellValueChanged", error);
        this.apiRequestError(error);
      }
    },
    onSelectionChanged() {
      this.selectionRows = this.gridApi.getSelectedRows();
    },
    async reset() {
      await this.onInit(this.orderModel);
    },
  },
  async beforeMount() {
    if (this.orderModel) {
      await this.onInit(this.orderModel);
    }
    this.columnDefs = [
      {
        colId: "selection",
        width: 50,
        editable: false,
        checkboxSelection: true,
      },
      { headerName: "行番号", field: "rowNumber", width: 70, editable: false },
      {
        field: "customerCode",
        headerName: "取引先名",
        width: 265,
        cellEditor: AutocompleteSelectCellEditor,
        cellEditorParams: {
          selectData: this.customers,
          placeholder: "取引先名入力",
          required: true,
          autocomplete: {
            showOnFocus: true,
          },
        },
        valueFormatter: (params) => {
          if (params.value) {
            return params.value.label || params.value.value || params.value;
          }
          return "";
        },
      },
      {
        field: "deliveryCode",
        headerName: "配送先",
        width: 265,
        cellEditor: AutocompleteSelectCellEditor,
        cellEditorParams: (params) => {
          return {
            selectData: this.deliverys.find((delivery) => delivery.customerCode == params.data.customerCode?.value)
              ?.deliverys,
            placeholder: "配送先入力",
            required: true,
            autocomplete: {
              showOnFocus: true,
            },
          };
        },
        valueFormatter: (params) => {
          if (params.value) {
            return params.value.label || params.value.value || params.value;
          }
          return "";
        },
      },
      {
        field: "quantity",
        type: "numericColumn",
        headerName: "数量",
        width: 100,
        valueFormatter: NumberValueFormatter(),
      },
    ];
  },
};
</script>
