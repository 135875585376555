<template>
  <v-card>
    <v-card-title>
      <span class="headline"><v-icon>mdi-database-edit</v-icon>返品一括編集</span>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-form ref="orderReturnBulkEditForm" lazy-validation>
        <v-container>
          <section-block>
            <v-row v-for="(source, index) in settingSources" :key="index" dense>
              <v-col cols="3">
                <v-select
                  :items="constant.orderReturnBulkEditColumnsValues"
                  label="設定項目"
                  v-model="source.column"
                  :rules="[rules.required, rules.validSelectColumn]"
                  @change="onSettingColSelected(index)"
                  dense
                  filled
                  attach
                ></v-select>
              </v-col>
              <v-col
                :cols="
                  source.column === constant.orderReturnBulkEditColumns.INSPECTION_GOOD_QUANTITY ||
                  source.column === constant.orderReturnBulkEditColumns.INSPECTION_BAD_QUANTITY
                    ? 2
                    : 4
                "
              >
                <v-select
                  v-if="source.column === constant.orderReturnBulkEditColumns.PRODUCT_CONDITION_TYPES"
                  :items="constant.productConditionTypeList"
                  label="商品状態区分"
                  v-model="source.value"
                  filled
                  dense
                  attach
                >
                </v-select>
                <v-text-field
                  v-if="source.column === constant.orderReturnBulkEditColumns.RETURN_QUANTITY"
                  label="返品数"
                  v-model="source.value"
                  @change="source.value = stringToNumber($event)"
                  :rules="[rules.isNumber, rules.maxLength(8), rules.isMinNumber(1), rules.validReturnQuantity]"
                  filled
                  dense
                >
                </v-text-field>
                <dp-date-picker
                  v-if="source.column === constant.orderReturnBulkEditColumns.DELIVERY_RESERVATION"
                  label="納品予約"
                  v-model="source.value"
                  filled
                  dense
                  type="date"
                ></dp-date-picker>
                <v-select
                  v-if="source.column === constant.orderReturnBulkEditColumns.DELIVERY_COMPANY"
                  :items="constant.deliveryCompanyList"
                  v-model="source.value"
                  label="配送業者"
                  filled
                  dense
                  attach
                ></v-select>
                <v-switch
                  v-if="source.column === constant.orderReturnBulkEditColumns.INSPECTION_RESULT"
                  inset
                  label="検品結果"
                  v-model="source.value"
                  filled
                  dense
                >
                </v-switch>
                <v-text-field
                  v-if="source.column === constant.orderReturnBulkEditColumns.INSPECTION_GOOD_QUANTITY"
                  label="検品数(良品)"
                  v-model="source.value"
                  filled
                  dense
                  @change="source.value = stringToNumber($event)"
                  :rules="[rules.isNumber, rules.maxLength(8)]"
                  :disabled="quantityCopyGood"
                >
                </v-text-field>
                <v-text-field
                  v-if="source.column === constant.orderReturnBulkEditColumns.INSPECTION_BAD_QUANTITY"
                  label="検品数(不良品)"
                  v-model="source.value"
                  filled
                  dense
                  @change="source.value = stringToNumber($event)"
                  :rules="[rules.isNumber, rules.maxLength(8)]"
                  :disabled="quantityCopyBad"
                >
                </v-text-field>
              </v-col>
              <v-col cols="2" v-if="source.column === constant.orderReturnBulkEditColumns.INSPECTION_GOOD_QUANTITY">
                <v-switch
                  inset
                  label="返品数を入力"
                  v-model="quantityCopyGood"
                  :disabled="quantityCopyBad"
                  filled
                  dense
                >
                </v-switch>
              </v-col>
              <v-col cols="2" v-if="source.column === constant.orderReturnBulkEditColumns.INSPECTION_BAD_QUANTITY">
                <v-switch
                  inset
                  label="返品数を入力"
                  v-model="quantityCopyBad"
                  :disabled="quantityCopyGood"
                  filled
                  dense
                >
                </v-switch>
              </v-col>
              <v-col cols="1"
                ><v-btn v-if="index != 0" x-small fab dense @click="onDelSettingSource(index)">
                  <v-icon dark> mdi-minus </v-icon></v-btn
                ></v-col
              >
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <v-btn x-small fab dense @click="onAddSettingSource">
                  <v-icon dark> mdi-plus </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </section-block>
          <v-divider class="mt-3 pt-3"></v-divider>
          <v-row dense>
            <v-col cols="12" sm="12">
              <v-spacer></v-spacer>
              <tooltip-icon-button
                :disabled="selectedRows.length == 0"
                icon="mdi-arrow-down-bold-outline"
                @click="onBtnSetUpdate"
                >一括反映</tooltip-icon-button
              >
            </v-col>
            <v-col cols="12" sm="12" style="height: 450px">
              <ag-grid-vue
                :domLayout="domLayout"
                class="ag-theme-alpine"
                :defaultColDef="defaultColDef"
                :columnDefs="columnDefs"
                :alwaysShowHorizontalScroll="true"
                :suppressColumnVirtualisation="true"
                :pagination="true"
                paginationPageSize="10"
                :localeText="localeText"
                rowSelection="multiple"
                @grid-ready="onGridReady"
                @column-everything-changed="onColumnEverythingChanged"
                @selection-changed="onSelectionChanged"
                :columnTypes="columnTypes"
                :getRowNodeId="(data) => data.orderReturnId"
                :frameworkComponents="frameworkComponents"
                @cell-value-changed="onCellValueChanged"
                style="height: 100%"
                @cell-editing-started="onCellEditingStarted"
              >
              </ag-grid-vue>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn color="secondary" @click="onCancelClick">キャンセル</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="onUpdateClick">更新</v-btn>
    </v-card-actions>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
    </v-overlay>
    <error-grid-dialog
      ref="updateErrorGrid"
      width="80%"
      height="80%"
      icon=""
      title="販売店返品一括編集"
      btnSubmit="登録"
      :columns="errorColumns"
    ></error-grid-dialog>
  </v-card>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import { AG_GRID_LOCALE_JA } from "../../models/ag-grid/locales";
import {
  NumericColumn,
  PercentColumn,
  DateColumn,
  FullDateColumn,
  CheckmarkColumn,
  EditableColumn,
  EditableDateColumn,
  EditableCheckBoxColumn,
  EditableCustomDateColumn,
} from "../../models/ag-grid/columnTypes";
import { ProtectCellRenderer } from "../../models/ag-grid/cellRenderers";
import { getColumnDef } from "../../models/ag-grid/helpers";
import TooltipIconButton from "../common/TooltipIconButton.vue";
import SectionBlock from "../common/SectionBlock.vue";
import {
  DateCellEditor,
  CheckboxCellEditor,
  CustomDateCellEditor,
  NumericCellEditor,
} from "../../components/ag-grid/cellEditors";
import ErrorGridDialog from "../../components/common/ErrorGridDialog.vue";
import { statuses as ApiStatus } from "../../libs/api-client";
import Validation from "../../libs/validation";
import {} from "../../components/ag-grid/cellEditors";
import { BooleanFilter, SelectionFilter } from "../../components/ag-grid/filters";
import OrderReturnBulkEditColumns from "../../consts/editColumns/OrderReturnBulkEditColumns";
import CafereoOrderReturnStatus from "../../consts/orderReturnStatus/CafereoOrderReturnStatuses";
import VendorOrderReturnStatus from "../../consts/orderReturnStatus/VendorOrderReturnStatuses";
import productConditionTypes from "../../consts/productConditionTypes";
import productConditionList from "../../consts/productConditionList";
import { DateValueFormatter } from "../../models/ag-grid/valueFormatters";
import ReturnTypes from "../../consts/ReturnTypes";
import DeliveryCompanys from "../../consts/DeliveryCompanys";
import ConvertUtils from "../../utils/ConvertUtils";
export default {
  name: "OrderReturnBulkEdit",
  props: ["inputModel"],
  components: {
    AgGridVue,
    TooltipIconButton,
    ErrorGridDialog,
    SectionBlock,
    /* eslint-disable vue/no-unused-components */
    DateCellEditor,
    CheckboxCellEditor,
    CustomDateCellEditor,
    NumericCellEditor,
  },
  data() {
    const checkStatuses = { true: "✔", false: "　" };
    return {
      updateModel: {},
      defaultModel: {
        productConditionType: null,
        returnQuantity: null,
        deliveryReservation: null,
        deliveryCompany: null,
        inspectionResult: null,
        inspectionGoodQuantity: null,
        inspectionBadQuantity: null,
      },
      constant: {
        orderReturnBulkEditColumnsValues: OrderReturnBulkEditColumns.all(),
        orderReturnBulkEditColumns: OrderReturnBulkEditColumns,
        productConditionTypeList: productConditionTypes.all(false),
        deliveryCompanyList: DeliveryCompanys.all(),
        productConditionSelectList: productConditionList.all(),
      },
      inspectedStatus: CafereoOrderReturnStatus.INSPECTED,
      vendorStatusList: VendorOrderReturnStatus.all(),

      defaultColDef: null,
      columnDefs: null,
      gridApi: null,
      columnApi: null,
      selectedRows: [],
      domLayout: null,
      localeText: AG_GRID_LOCALE_JA,
      updateList: [],
      settingSources: [],
      quantityCopyGood: false,
      quantityCopyBad: false,
      columnTypes: {
        dpNumericColumn: NumericColumn,
        dpPercentColumn: PercentColumn,
        dpDateColumn: DateColumn,
        dpFullDateColumn: FullDateColumn,
        dpCheckmarkColumn: CheckmarkColumn,
        dpEditableColumn: EditableColumn,
        dpEditableDateColumn: EditableDateColumn,
        dpEditableCheckBoxColumn: EditableCheckBoxColumn,
        dpEditableCustomDateColumn: EditableCustomDateColumn,
      },
      frameworkComponents: {
        dpDateCellEditor: DateCellEditor,
        dpCheckboxCellEditor: CheckboxCellEditor,
        dpBooleanFilter: BooleanFilter,
        dpSelectionFilter: SelectionFilter,
        dpCellDatePicker: CustomDateCellEditor,
        dpNumericCellEditor: NumericCellEditor,
      },
      errorRows: [],
      updateSuccessRecords: [],
      rules: {
        required: Validation.required,
        isNumber: Validation.isNumber,
        maxLength: Validation.maxLength,
        isMinNumber: Validation.isMinNumber,
        validSelectColumn: (value) => this.validSelectColumn(value),
        validReturnQuantity: (value) => this.validReturnQuantity(value),
      },
      stringToNumber: ConvertUtils.stringToNumber,
      searchFlg: false,
      cafereoColumns: [
        { headerName: "登録日", field: "createDatetime", type: "dpDateColumn", hide: true },
        { headerName: "返品受注ID", field: "orderReturnId", pinned: "left" },
        {
          headerName: "返品受注ステータス",
          field: "orderReturnStatus",
          filter: "dpSelectionFilter",
          filterParams: { options: CafereoOrderReturnStatus.all() },
          valueGetter: (params) => CafereoOrderReturnStatus.of(params.data.orderReturnStatus),
        },
        { headerName: "社店CD", field: "customerCode", hide: true },
        { headerName: "法人名", field: "corporationName" },
        { headerName: "取引先名", field: "customerName" },
        {
          headerName: "商品状態区分",
          field: "productConditionType",
          filter: "dpSelectionFilter",
          filterParams: { options: productConditionList.all() },
          editable: true,
          cellEditor: "agSelectCellEditor",
          cellEditorParams: {
            values: Object.keys(productConditionList.refDataArray()),
            cellEditorPopup: true,
          },
          refData: productConditionList.refDataArray(),
          cellStyle: (params) => {
            let ret = { backgroundColor: "#FFFFCC" };
            if (params.data.updateProductConditionType) {
              ret.backgroundColor = "#CCFFCC";
            }
            return ret;
          },
        },
        { headerName: "JANCODE", field: "janCode" },
        { headerName: "タイトル", field: "title" },
        { headerName: "商品名", field: "productName" },
        { headerName: "上代", field: "retailPrice", type: "dpNumericColumn" },
        {
          headerName: "卸掛率",
          field: "wholesaleRate",
          type: "dpPercentColumn",
          valueGetter: (params) => params.data.wholesaleRate.toFixed(1),
        },
        { headerName: "卸単価", field: "unitPrice", type: "dpNumericColumn" },
        { headerName: "出荷数", field: "orderQuantity", type: "dpNumericColumn" },
        {
          headerName: "返品数",
          field: "returnQuantity",
          type: "dpNumericColumn",
          editable: true,
          cellStyle: (params) => {
            let ret = { backgroundColor: "#FFFFCC" };
            if (params.data.updateReturnQuantity) {
              ret.backgroundColor = "#CCFFCC";
            }
            return ret;
          },
        },
        { headerName: "返品単価合計", field: "unitReturnPriceTotal", type: "dpNumericColumn" },
        { headerName: "メーカー直送", field: "directDeliveryFlg", type: "dpCheckmarkColumn" },
        { headerName: "承認者", field: "approvalUser" },
        { headerName: "承認日時", field: "approvalDatetime", type: "dpDateColumn" },
        {
          headerName: "納品予約",
          field: "deliveryReservation",
          type: "dpEditableCustomDateColumn",
          filter: "agDateColumnFilter",
          cellEditorFramework: "CustomDateCellEditor",
          valueFormatter: DateValueFormatter("YYYY/MM/DD"),
          cellStyle: (params) => {
            let ret = { backgroundColor: "#FFFFCC" };
            if (params.data.updateDeliveryReservation) {
              ret.backgroundColor = "#CCFFCC";
            }
            return ret;
          },
        },
        {
          headerName: "配送業者",
          field: "deliveryCompany",
          filter: "dpSelectionFilter",
          filterParams: { options: DeliveryCompanys.all() },
          editable: true,
          cellEditor: "agSelectCellEditor",
          cellEditorPopup: true,
          cellEditorParams: {
            values: Object.keys(DeliveryCompanys.refDataArray()),
            cellEditorPopup: true,
          },
          refData: DeliveryCompanys.refDataArray(),
          cellStyle: (params) => {
            let ret = { backgroundColor: "#FFFFCC" };
            if (params.data.updateDeliveryCompany) {
              ret.backgroundColor = "#CCFFCC";
            }
            return ret;
          },
        },
        {
          headerName: "検品結果",
          field: "inspectionResult",
          filter: "dpBooleanFilter",
          filterParams: { caption: checkStatuses },
          editable: true,
          cellEditor: "agSelectCellEditor",
          cellEditorParams: {
            values: Object.keys(checkStatuses),
            cellEditorPopup: true,
          },
          refData: checkStatuses,
          cellStyle: (params) => {
            let ret = { backgroundColor: "#FFFFCC" };
            if (params.data.updateInspectionResult) {
              ret.backgroundColor = "#CCFFCC";
            }
            return ret;
          },
        },
        {
          headerName: "検品数(良品)",
          field: "inspectionGoodQuantity",
          type: "dpNumericColumn",
          editable: true,
          cellStyle: (params) => {
            let ret = { backgroundColor: "#FFFFCC" };
            if (params.data.updateInspectionGoodQuantity) {
              ret.backgroundColor = "#CCFFCC";
            }
            return ret;
          },
        },
        {
          headerName: "検品数(不良品)",
          field: "inspectionBadQuantity",
          type: "dpNumericColumn",
          editable: true,
          cellStyle: (params) => {
            let ret = { backgroundColor: "#FFFFCC" };
            if (params.data.updateInspectionBadQuantity) {
              ret.backgroundColor = "#CCFFCC";
            }
            return ret;
          },
        },
        { headerName: "配送CD", field: "deliveryCode", hide: true },
        { headerName: "配送先〒", field: "deliveryPostalCode", hide: true },
        { headerName: "配送先住所", field: "deliveryAddress", hide: true },
        { headerName: "届先法人名", field: "deliveryName1", hide: true },
        { headerName: " 届先名称", field: "deliveryName2", hide: true },
        { headerName: "登録日時", field: "createDatetime", type: "dpDateColumn", hide: true },
        { headerName: "登録者", field: "createUser", hide: true },
        { headerName: "更新日時", field: "updateDatetime", type: "dpDateColumn", hide: true },
        { headerName: "更新者", field: "updateUser", hide: true },
      ],
      errorColumns: [
        { headerName: "返品ID", field: "orderReturnId" },
        {
          headerName: "エラー内容",
          field: "errorMessage",
          wrapText: true,
          autoHeight: true,
          cellRenderer: function (param) {
            return param.data.errorMessage.join("<br>");
          },
        },
      ],
    };
  },
  watch: {
    inputModel(inputModel) {
      this.init(inputModel);
    },
  },
  computed: {
    isLoading() {
      return this.$store.getters["ui/isLoading"];
    },
    isQuantityDisabled() {
      if (
        this.source.returnType == ReturnTypes.CONSIGNMENT &&
        this.source.productConditionType == productConditionTypes.GOOD
      ) {
        return true;
      }
      return false;
    },
  },
  methods: {
    // ****************************
    // ag-grid定義
    // ****************************
    setColumnDefs() {
      let columnDefs = [
        {
          headerName: "",
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          checkboxSelection: true,
          filter: false,
          resizable: false,
          sortable: false,
          pinned: "left",
          cellRenderer: (params) => {
            return ProtectCellRenderer({ matcher: "orderLocked" })(params);
          },
        },
      ];
      columnDefs = columnDefs.concat(this.cafereoColumns);
      // ag-grid内のvalidation等をセット
      getColumnDef(columnDefs, "returnQuantity").valueSetter = this.valueSetterReturnQuantity;
      getColumnDef(columnDefs, "inspectionResult").valueSetter = this.valueSetterInspectionResult;
      getColumnDef(columnDefs, "inspectionGoodQuantity").valueSetter = this.valueSetterInspectionGoodQuantity;
      getColumnDef(columnDefs, "inspectionBadQuantity").valueSetter = this.valueSetterInspectionBadQuantity;
      return columnDefs;
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;

      this.init(this.inputModel);

      var allColumnIds = [];
      this.columnApi.getAllColumns().forEach(function (column) {
        allColumnIds.push(column.colId);
      });
      this.columnApi.autoSizeColumns(allColumnIds);
      this.gridApi.refreshCells();
    },
    onColumnEverythingChanged() {
      var allColumnIds = [];
      this.columnApi?.getAllColumns().forEach(function (column) {
        allColumnIds.push(column.colId);
      });
      this.columnApi?.autoSizeColumns(allColumnIds);
      this.gridApi?.refreshCells();
    },
    onSelectionChanged() {
      this.selectedRows = this.gridApi.getSelectedRows();
    },
    onCellEditingStarted(event) {
      const popupTop = event.api.getCellEditorInstances()[0].getGui().style.top;
      event.api.getCellEditorInstances()[0].getGui().style.top = parseInt(popupTop) - 40 + "px";
    },
    valueSetterReturnQuantity(params) {
      let isNumber = Validation.isNumber(params.newValue);
      if (isNumber !== true) {
        this.$dialog.notify.error("返品数は" + isNumber, { timeout: 2300 });
        return false;
      }
      //返品区分が委託・消化仕入かつ、商品状態区分が良品の場合何もしない
      if (
        params.data.returnType == this.stringToNumber(ReturnTypes.CONSIGNMENT) &&
        params.data.productConditionType == productConditionTypes.GOOD
      ) {
        this.$dialog.notify.error("返品区分が委託・消化仕入で、商品状態区分が良品の為、返品数は変更できません", {
          timeout: 2300,
        });
        return false;
      }
      if (Number(params.newValue) < 1) {
        this.$dialog.notify.error("1以上を入力してください", { timeout: 2300 });
        return false;
      }
      if (Number(params.data.orderQuantity) < Number(params.newValue)) {
        this.$dialog.notify.error("返品数は出荷数を超えて入力できません", { timeout: 2300 });
        return false;
      }
      params.data.returnQuantity = Number(params.newValue);
      params.data.updateReturnQuantity = true;
      return true;
    },
    valueSetterInspectionResult(params) {
      if (
        (params.data.inspectionGoodQuantity && Number(params.data.inspectionGoodQuantity > 0)) ||
        (params.data.inspectionBadQuantity && Number(params.data.inspectionBadQuantity > 0))
      ) {
        this.$dialog.notify.error("検品数(良品)または検品数(不良品)の何れかが登録済みの場合変更できません", {
          timeout: 2300,
        });
        return false;
      }
      params.data.inspectionResult = params.newValue;

      params.data.updateInspectionResult = true;
      return true;
    },
    valueSetterInspectionGoodQuantity(params) {
      let isNumber = Validation.isNumber(params.newValue);
      if (isNumber !== true) {
        this.$dialog.notify.error("検品数(良品)は" + isNumber, { timeout: 2300 });
        return false;
      }
      if (String(params.newValue).length > 8) {
        this.$dialog.notify.error("8桁以下で入力してください", { timeout: 2300 });
        return false;
      }
      console.log(params.data);
      if (!params.data.inspectionResult || params.data.inspectionResult == "false") {
        this.$dialog.notify.error("検品結果にチェックしてください", { timeout: 2300 });
        return false;
      }
      params.data.inspectionGoodQuantity = params.newValue ? Number(params.newValue) : null;
      params.data.updateInspectionGoodQuantity = true;
      return true;
    },
    valueSetterInspectionBadQuantity(params) {
      let isNumber = Validation.isNumber(params.newValue);
      if (isNumber !== true) {
        this.$dialog.notify.error("検品数(不良品)は" + isNumber, { timeout: 2300 });
        return false;
      }
      if (String(params.newValue).length > 8) {
        this.$dialog.notify.error("8桁以下で入力してください", { timeout: 2300 });
        return false;
      }
      console.log(params.data);
      if (!params.data.inspectionResult || params.data.inspectionResult == "false") {
        this.$dialog.notify.error("検品結果にチェックしてください", { timeout: 2300 });
        return false;
      }
      params.data.inspectionBadQuantity = params.newValue ? Number(params.newValue) : null;
      params.data.updateInspectionBadQuantity = true;
      return true;
    },
    validReturnQuantity() {
      if (Number(this.updateModel.orderQuantity) < Number(this.updateModel.returnQuantity))
        return "返品数が出荷数を超えています。";
      return true;
    },
    // ****************************
    // 画面アクション定義
    // ****************************
    init(inputModel) {
      this.updateModel = this.defaultModel;
      this.gridApi.setRowData(JSON.parse(JSON.stringify(inputModel)));
      this.updateSuccessRecords = [];
      // 初期表示時、設定項目を１つ表示する。
      this.onAddSettingSource();
      if (this.$refs.orderReturnBulkEditForm) {
        this.$refs.orderReturnBulkEditForm.resetValidation();
      }
    },
    reset() {
      this.init(this.inputModel);
      this.updateSuccessRecords = [];
    },
    onCancelClick() {
      this.$emit("onDialogClose", this.updateSuccessRecords);
    },
    async onUpdateClick() {
      var requestRecords = [];
      this.errorRows = [];
      var isValid = true;
      this.gridApi.forEachNode((record) => {
        var error = this.validateRow(record.data);
        if (error != true) {
          this.errorRows.push({
            orderReturnId: record.data.orderReturnId,
            errorMessage: error,
          });
          isValid = false;
        } else {
          requestRecords.push(this.requestFormat(record.data));
        }
      });
      if (isValid) {
        try {
          this.loadingOn();
          var param = {
            orderReturnList: requestRecords,
          };
          const response = await this.$store.dispatch("orderReturn/bulkupdate", param);
          let error = response.data?.header;
          // 一覧更新レコード
          this.searchFlg = true;
          let updateRows = [];
          this.errorRows = [];
          switch (error.resultCode) {
            case ApiStatus.consts.SUCCESS:
            case ApiStatus.consts.BUSINESS_ERROR:
            case ApiStatus.consts.ALREADY_CHANGED:
              // エラーメッセージ格納
              if (error.messages) {
                Object.keys(error.messages).forEach((key) => {
                  this.errorRows.push({
                    orderReturnId: key,
                    errorMessage: error.messages[key],
                  });
                });
              }
              // 一覧部の更新分のレコード設定
              if (response.data.contents) {
                this.gridApi.forEachNode((row) => {
                  response.data.contents.orderReturnList.forEach((order) => {
                    let isError = this.errorRows.some((r) => r.orderReturnId === row.data.orderReturnId);
                    if (order.orderReturnId == row.data.orderReturnId && !isError) {
                      this.updateSuccessRecords.push(order);
                      updateRows.push(order);
                    }
                  });
                });
              }
              // エラー表示
              if (this.errorRows.length > 0) {
                // 確認ダイアログから更新した分を削除(エラーがある場合のみ)
                this.gridApi.applyTransaction({ remove: updateRows });
                this.$refs.updateErrorGrid.open({ records: this.errorRows });
                this.$dialog.notify.error(`返品情報の更新処理に失敗したデータが存在します。ご確認ください。`, {
                  timeout: 2300,
                });
              } else {
                this.$dialog.notify.info(`返品情報を更新しました`, { timeout: 2300 });
                this.$refs.updateErrorGrid.close();
                this.$emit("onEditSubmit", this.updateSuccessRecords);
                this.reset();
              }
              break;
            default:
              this.redirectError();
              break;
          }
        } catch (error) {
          console.error("OrderReturnBulkEdit_update::onSubmit", error);
          this.apiRequestError(error);
        } finally {
          this.loadingOff();
        }
      } else {
        this.$refs.updateErrorGrid.open({ records: this.errorRows });
        this.$dialog.notify.error(`更新データに入力エラーが存在します。ご確認ください。`, {
          timeout: 2300,
        });
      }
    },
    requestFormat(row) {
      row.productConditionType = Number(row.productConditionType);
      row.returnQuantity = Number(row.returnQuantity);
      row.deliveryCompany = String(row.deliveryCompany);
      console.log("row.inspectionResult:" + row.inspectionResult);
      row.inspectionResult = row.inspectionResult === "true" || row.inspectionResult === true ? true : false;
      // 値が設定されている場合数値に変換し、それ以外かつ検品結果がtrueの場合０を設定、条件に当てはまらない場合NULL
      if (row.inspectionGoodQuantity) {
        row.inspectionGoodQuantity = Number(row.inspectionGoodQuantity);
      } else if (row.inspectionResult) {
        row.inspectionGoodQuantity = 0;
      } else {
        row.inspectionGoodQuantity = null;
      }
      // 値が設定されている場合数値に変換し、それ以外かつ検品結果がtrueの場合０を設定、条件に当てはまらない場合NULL
      if (row.inspectionBadQuantity) {
        row.inspectionBadQuantity = Number(row.inspectionBadQuantity);
      } else if (row.inspectionResult) {
        row.inspectionBadQuantity = 0;
      } else {
        row.inspectionBadQuantity = null;
      }
      return row;
    },
    onCellValueChanged(params) {
      // セルの色を変えるため項目更新をチェック
      if (params.colDef.field == "productConditionType") {
        params.data.updateProductConditionType = true;
      } else if (params.colDef.field == "returnQuantity") {
        this.onQuantityChanged(params.data);
        params.data.updateReturnQuantity = true;
      } else if (params.colDef.field == "deliveryReservation") {
        params.data.updateDeliveryReservation = true;
      } else if (params.colDef.field == "deliveryCompany") {
        params.data.updateDeliveryCompany = true;
      } else if (params.colDef.field == "inspectionResult") {
        params.data.updateInspectionResult = true;
      } else if (params.colDef.field == "inspectionGoodQuantity") {
        params.data.updateInspectionGoodQuantity = true;
      } else if (params.colDef.field == "inspectionBadQuantity") {
        params.data.updateInspectionBadQuantity = true;
      }
      let rows = [];
      rows.push(params.node);
      this.gridApi.refreshCells({ force: true, rowNodes: rows });
    },
    onSettingColSelected(index) {
      this.settingSources[index].value = "";
    },
    onAddSettingSource() {
      let settingSource = {
        column: 0,
        value: "",
      };
      this.settingSources.push(settingSource);
    },
    onDelSettingSource(index) {
      this.settingSources.splice(index, 1);
    },
    onBtnSetUpdate() {
      if (this.validate()) {
        this.selectedRows.forEach((row) => {
          this.settingSources.forEach((source) => {
            if (source.column === this.constant.orderReturnBulkEditColumns.PRODUCT_CONDITION_TYPES) {
              // 商品状態区分
              row.productConditionType = source.value;
              row.updateProductConditionType = true;
            } else if (source.column === this.constant.orderReturnBulkEditColumns.RETURN_QUANTITY) {
              // 返品数
              //返品区分が委託・消化仕入:2かつ、商品状態区分が良品:1の場合、設定しない
              // また、返品数が出荷数を超える場合も適用しない
              if (
                (row.returnType !== this.stringToNumber(ReturnTypes.CONSIGNMENT) ||
                  row.productConditionType !== productConditionTypes.GOOD) &&
                row.orderQuantity >= source.value
              ) {
                row.returnQuantity = source.value;
                this.onQuantityChanged(row);
                row.updateReturnQuantity = true;
              }
            } else if (source.column === this.constant.orderReturnBulkEditColumns.DELIVERY_RESERVATION) {
              // 納品予約
              row.deliveryReservation = source.value;
              row.updateDeliveryReservation = true;
            } else if (source.column === this.constant.orderReturnBulkEditColumns.DELIVERY_COMPANY) {
              // 配送業者
              row.deliveryCompany = source.value;
              row.updateDeliveryCompany = true;
            } else if (source.column === this.constant.orderReturnBulkEditColumns.INSPECTION_RESULT) {
              // 検品結果
              // 検品数(良品)か検品数(不良品)のいづれかが１度でも登録された場合は無効
              if (
                (!row.inspectionGoodQuantity || Number(row.inspectionGoodQuantity == 0)) &&
                (!row.inspectionBadQuantity || Number(row.inspectionBadQuantity == 0))
              ) {
                row.inspectionResult = source.value;
                row.updateInspectionResult = true;
              }
            } else if (source.column === this.constant.orderReturnBulkEditColumns.INSPECTION_GOOD_QUANTITY) {
              // 検品数（良品）
              if (this.quantityCopyGood) {
                row.inspectionGoodQuantity = row.returnQuantity;
              } else {
                row.inspectionGoodQuantity = source.value;
              }
              row.updateInspectionGoodQuantity = true;
            } else if (source.column === this.constant.orderReturnBulkEditColumns.INSPECTION_BAD_QUANTITY) {
              // 検品数（不良品）
              if (this.quantityCopyBad) {
                row.inspectionBadQuantity = row.returnQuantity;
              } else {
                row.inspectionBadQuantity = source.value;
              }
              row.updateInspectionBadQuantity = true;
            }
          });
        });
        this.gridApi.applyTransaction({ update: this.selectedRows });
        this.updateModel = this.defaultModel;
      }
    },
    // ****************************
    // 共通業務処理
    // ****************************
    onQuantityChanged(row) {
      row.unitReturnPriceTotal = row.unitPrice * row.returnQuantity;
    },
    // ****************************
    // Validation定義
    // ****************************
    validate() {
      const isValid = this.$refs.orderReturnBulkEditForm.validate();
      if (!isValid) {
        this.$dialog.error({ text: "入力エラーがあります", actions: ["OK"] });
      }
      return isValid;
    },
    validateRow(row) {
      var ret = true;
      var messages = [];
      // 商品状態区分
      this.setValidMessage(this.rules.required(row.productConditionType), "商品状態区分", messages);
      // 返品数
      this.setValidMessage(this.rules.required(row.returnQuantity), "返品数", messages);
      this.setValidMessage(this.rules.isNumber(row.returnQuantity), "返品数", messages);
      this.setValidMessage(this.rules.maxLength(8)(row.returnQuantity), "返品数", messages);
      this.setValidMessage(this.rules.isMinNumber(1)(row.returnQuantity), "返品数", messages);
      this.setValidMessage(this.rules.validReturnQuantity(row.returnQuantity), "返品数", messages);
      // 検品結果
      this.setValidMessage(this.rules.required(row.inspectionResult), "検品結果", messages);
      // 検品数(良品)
      this.setValidMessage(this.rules.isNumber(row.inspectionGoodQuantity), "検品数(良品)", messages);
      this.setValidMessage(this.rules.maxLength(8)(row.inspectionGoodQuantity), "検品数(良品)", messages);
      // 検品数(不良品)
      this.setValidMessage(this.rules.isNumber(row.inspectionBadQuantity), "検品数(不良品)", messages);
      this.setValidMessage(this.rules.maxLength(8)(row.inspectionBadQuantity), "検品数(不良品)", messages);

      if (messages.length > 0) ret = messages;
      return ret;
    },
    validSelectColumn(value) {
      if (value == null) return true;
      if (this.settingSources.filter((settingSource) => settingSource.column === value).length > 1)
        return "同一項目が選択されています。";
      return true;
    },
    setValidMessage(check, culumnName, messages) {
      if (!(check === true)) messages.push(culumnName + "は" + check);
    },
    booleanFormat(value) {
      if (value == null || value == "") return false;
      if (value == "Y") return true;
      if (value == "N") return false;
      return Boolean(value);
    },
  },

  beforeMount() {
    this.domLayout = "autoHeight";
    this.defaultColDef = {
      filter: "agTextColumnFilter",
      resizable: true,
      sortable: true,
      suppressSizeToFit: true,
      filterParams: {
        newRowsAction: "keep",
      },
      maxWidth: 300,
    };
    this.columnDefs = this.setColumnDefs();
  },
};
</script>
