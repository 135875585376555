import { createDefault } from "../../utils/ConstUtils";

export default createDefault([
  { value: 1, text: "商品状態区分", enum: "PRODUCT_CONDITION_TYPES" },
  { value: 2, text: "返品数", enum: "RETURN_QUANTITY" },
  { value: 3, text: "納品予約", enum: "DELIVERY_RESERVATION" },
  { value: 4, text: "配送業者", enum: "DELIVERY_COMPANY" },
  { value: 5, text: "検品結果", enum: "INSPECTION_RESULT" },
  { value: 6, text: "検品数（良品）", enum: "INSPECTION_GOOD_QUANTITY" },
  { value: 7, text: "検品数（不良品）", enum: "INSPECTION_BAD_QUANTITY" },
]);
