<template>
  <v-autocomplete
    :label="label"
    :value="current"
    :items="candidate"
    :loading="loading"
    item-value="userId"
    item-text="userName"
    return-object
    :disabled="disabled"
    :rules="rules"
    @change="onSelected"
    clearable
    hide-no-data
    filled
    dense
    multiple
    :attach="attach"
  ></v-autocomplete>
</template>

<script>
export default {
  name: "CafereoUsersField",
  model: { prop: "value", event: "selected" },
  props: {
    label: { type: String, default: "担当" },
    value: { type: Array, default: () => [] },
    disabled: { type: Boolean, default: false },
    attach: { type: Boolean, default: true },
    rules: { type: Array, default: () => [] },
    param: { type: Array, default: () => [] },
    inactivated: { type: Boolean, default: false },
  },
  data() {
    return {
      loading: null,
      candidate: [],
      current: null,
    };
  },
  async mounted() {
    await this.setup();
    this.apply(this.value);
  },
  watch: {
    value(value) {
      this.apply(value);
    },
  },
  methods: {
    async setup() {
      try {
        this.loading = true;
        const params = { inactivated: this.inactivated };
        const response = await this.$store.dispatch("cafereoUser/search", params);
        if (response.data?.contents?.users) {
          this.candidate = response.data.contents.users;
        } else {
          this.candidate = [];
        }
      } catch (error) {
        console.error("CafereoUsersField::setup", error);
      } finally {
        this.loading = false;
      }
    },
    apply(value) {
      if (value) {
        let currentUserIds = [];
        for (let i = 0; i < this.current?.length; i++) {
          currentUserIds.push(this.current[i].userId);
        }
        let numOnlyInVal = value.filter((i) => currentUserIds.indexOf(i) == -1);
        let numOnlyInCurrent = currentUserIds.filter((i) => value.indexOf(i) == -1);

        if (numOnlyInVal.length == 0 && numOnlyInCurrent.length == 0) {
          // NOP
        } else {
          if (value.length > 0) {
            let current = [];
            for (let i = 0; i < value.length; i++) {
              let tmpCurrent = this.candidate.find((c) => value[i] === c.userId);
              if (tmpCurrent) {
                current.push(tmpCurrent);
              }
            }
            this.onSelected(current);
          }
        }
      } else {
        this.current = null;
      }
    },
    selected() {
      return this.current;
    },
    onSelected(value) {
      this.current = value;
      if (value.length > 0) {
        let userIds = [];
        for (let i = 0; i < value.length; i++) {
          userIds.push(value[i].userId);
        }
        this.$emit("selected", userIds);
      } else {
        this.$emit("selected", null);
      }
    },
  },
};
</script>
