<template>
  <v-card>
    <v-card-title>
      <span class="headline"><v-icon>mdi-pencil-outline</v-icon>注残分割</span>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-form ref="orderForm" v-model="validEntryForm" lazy-validation>
        <v-container style="max-width: inherit">
          <v-row dense>
            <v-col cols="12" sm="3">
              <v-text-field label="受注ID" :value="orderModel.orderId" filled dense disabled></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field label="JANCODE" :value="orderModel.janCode" filled dense disabled></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field label="タイトル" :value="orderModel.title" filled dense disabled></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field label="商品名" :value="orderModel.productName" filled dense disabled></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <v-text-field
                label="受注数"
                v-model="backOrderQuantity"
                :rules="[rules.backOrderQuantity]"
                filled
                dense
                :error-messages="backOrderQuantityErrorMessages"
                @change="backOrderQuantity = $event !== '' ? $event : null"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <v-text-field
                label="上代金額"
                :value="orderModel.retailPrice | comma"
                filled
                dense
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <v-text-field label="卸掛率" :value="orderModel.wholesaleRate" filled dense disabled></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <v-text-field label="商品単価" :value="orderModel.unitPrice | comma" filled dense disabled></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <v-text-field
                label="上代合計"
                :value="orderModel.retailPriceTotal | comma"
                filled
                dense
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <v-text-field label="商品単価合計" :value="unitPriceTotal | comma" filled dense disabled></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn color="secondary" @click="onCancelClick">キャンセル</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="onCreateClick">分割登録</v-btn>
    </v-card-actions>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
    </v-overlay>
  </v-card>
</template>

<script>
import { comma } from "../../filter/NumberFilter";
import { statuses as ApiStatus } from "../../libs/api-client";

export default {
  name: "BackOrderEntry",
  props: ["orderModel"],
  filters: { comma },
  data() {
    return {
      backOrderQuantity: this.orderModel.backOrderQuantity,
      validEntryForm: null,
      backOrderQuantityErrorMessages: [],
      rules: {
        backOrderQuantity: (value) => this.backOrderQuantityRules(value),
      },
    };
  },
  watch: {
    orderModel(orderModel) {
      this.backOrderQuantity = orderModel.backOrderQuantity;
      orderModel.retailPriceTotal = orderModel.retailPrice * orderModel.backOrderQuantity;
    },
  },
  computed: {
    unitPriceTotal() {
      return this.orderModel.unitPrice * (this.backOrderQuantity || 0);
    },
    isLoading() {
      return this.$store.getters["ui/isLoading"];
    },
  },
  methods: {
    async onCreateClick() {
      this.resetError();
      if (this.validate()) {
        try {
          this.loadingOn();
          const updateModel = {
            orderId: this.orderModel.orderId,
            orderQuantity: Number(this.backOrderQuantity),
            lastUpdateDatetime: this.orderModel.updateDatetime,
          };
          const response = await this.$store.dispatch("order/splitRemaining", { order: updateModel });
          switch (response.data?.header.resultCode) {
            case ApiStatus.consts.SUCCESS:
              this.$dialog.notify.info(`注文残を新規受注として分割登録しました (${response.data.contents.orderId})`, {
                timeout: 2300,
              });
              this.$emit("created");
              break;
            case ApiStatus.consts.BUSINESS_ERROR:
            case ApiStatus.consts.ALREADY_CHANGED:
              var message = this.setError(response.data?.header.messages);
              if (message != "") {
                this.$dialog.warning({
                  title: `注残分割`,
                  text: message,
                  actions: ["OK"],
                });
              }
              break;
            default:
              this.redirectError();
              break;
          }
        } catch (error) {
          console.error("BackOrderEntry::onCreateClick", error);
          this.apiRequestError(error);
        } finally {
          this.loadingOff();
        }
      }
    },
    onCancelClick() {
      this.$emit("cancel");
    },
    validate() {
      const isValid = this.$refs.orderForm.validate();
      if (!isValid) {
        this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
      }
      return isValid;
    },
    setError(responseMessage) {
      var messages = [];
      var message = "";
      Object.keys(responseMessage).forEach((key) => {
        messages.push(responseMessage[key]);
      });
      if (messages.length > 0) message = messages.join("<br>");
      return message;
    },
    backOrderQuantityRules(value) {
      if (value == null || Number(value) < 1) return "1以上を入力してください";
      if (Number(value) > Number(this.orderModel.orderQuantity)) return `受注数以下を入力してください`;
      return true;
    },
    resetError() {
      this.backOrderQuantityErrorMessages = [];
    },
  },
};
</script>
