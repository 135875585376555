<template>
  <v-autocomplete
    :label="label"
    :value="current"
    :items="candidate"
    :loading="loading"
    item-value="corporateCode"
    item-text="corporationName"
    return-object
    :disabled="disabled"
    :readonly="readonly"
    :rules="rules"
    @change="onSelected"
    :clearable="!readonly"
    hide-no-data
    filled
    dense
    :attach="attach"
  ></v-autocomplete>
</template>

<script>
import CorporationTypes from "../../../consts/CorporationTypes";
export default {
  name: "CorporationField",
  model: { prop: "value", event: "selected" },
  props: {
    label: { type: String, default: "法人名" },
    value: { type: String, default: null },
    disabled: { type: Boolean, default: false },
    attach: { type: Boolean, default: true },
    readonly: { type: Boolean, default: false },
    rules: { type: Array, default: () => [] },
    param: { type: Array, default: () => [] },
    group: { type: Number, default: null },
    group2: { type: Number, default: null },
    pauseDisplayFlg: { type: Boolean, default: true },
  },
  data() {
    return {
      loading: null,
      candidate: [],
      current: null,
    };
  },
  async mounted() {
    await this.setup();
    this.apply(this.value);
  },
  watch: {
    value(value) {
      this.apply(value);
    },
  },
  methods: {
    async setup() {
      try {
        this.loading = true;
        let customerFlg = false;
        let mekarFlg = false;

        if (this.group == CorporationTypes.VENDOR || this.group2 == CorporationTypes.VENDOR) {
          customerFlg = true;
        }

        if (this.group == CorporationTypes.MAKER || this.group2 == CorporationTypes.MAKER) {
          mekarFlg = true;
        }

        const params = { customerFlg: customerFlg, mekarFlg: mekarFlg, pauseDisplayFlg: this.pauseDisplayFlg };
        const response = await this.$store.dispatch("corporation/suggest", params);
        if (response.data?.contents?.corporations) {
          this.candidate = response.data.contents.corporations;
        } else {
          this.candidate = [];
        }
      } catch (error) {
        console.error("CafereoCorporationsField::setup", error);
      } finally {
        this.loading = false;
      }
    },
    apply(value) {
      if (value) {
        if (value == this.current?.corporateCode) {
          // NOP
        } else {
          const current = this.candidate.find((c) => value === c.corporateCode);
          this.onSelected(current);
        }
      } else {
        this.current = null;
      }
    },
    selected() {
      return this.current;
    },
    onSelected(value) {
      this.current = value;
      if (value) {
        this.$emit("selected", value.corporateCode);
      } else {
        this.$emit("selected", null);
      }
    },
  },
};
</script>
