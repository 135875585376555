import { createDefault } from "../../utils/ConstUtils";

const Cafereo = createDefault([
  { value: 1, text: "受注数", enum: "ORDER_QUANTITY" },
  { value: 2, text: "卸掛率", enum: "WHOLESALE_RATE" },
  { value: 3, text: "配送先名", enum: "SUPPLIER_DELIVERY_CD" },
  { value: 4, text: "直送依頼", enum: "DIRECT_DELIVERY_REQUEST_FLG" },
  { value: 5, text: "備考", enum: "REMARKS" },
  { value: 6, text: "社内備考", enum: "CAFEREO_REMARKS" },
  { value: 7, text: "伝票備考", enum: "SLIP_REMARKS" },
  { value: 8, text: "取引先専用番号", enum: "PARTNER_PURCHASE_NO" },
  { value: 9, text: "取引先専用明細番号", enum: "PARTNER_DETAIL_NO" },
  { value: 10, text: "出荷予定日", enum: "SHIP_PLAN_DATE" },
]);

// 販売店発注一覧カラム
const Vendor = createDefault([
  { value: 1, text: "発注数", enum: "ORDER_QUANTITY" },
  { value: 3, text: "配送先名", enum: "SUPPLIER_DELIVERY_CD" },
  { value: 8, text: "取引先専用番号", enum: "PARTNER_PURCHASE_NO" },
  { value: 9, text: "取引先専用明細番号", enum: "PARTNER_DETAIL_NO" },
]);

export default { Cafereo, Vendor };
export { Cafereo, Vendor };
