<template>
  <v-card>
    <v-card-subtitle class="text-subtitle-1">{{ `割当元${this.screen}` }}</v-card-subtitle>
    <v-card-text>
      <v-form>
        <v-container class="pa-0">
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                :label="`${this.screen}ID`"
                filled
                dense
                hide-details
                readonly
                :value="order.orderId"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field label="JANCODE" filled dense hide-details readonly :value="order.janCode"></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field label="タイトル" filled dense hide-details readonly :value="order.title"></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field label="商品名" filled dense hide-details readonly :value="order.productName"></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                :label="`${this.screen}数量`"
                filled
                dense
                hide-details
                readonly
                :value="order.orderQuantity | comma"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-card-subtitle class="mt-3 text-subtitle-1">{{ `割当後${this.screen}一覧` }}</v-card-subtitle>
    <v-card-text>
      <ag-grid-vue :gridOptions="gridOptions" :rowData="assginRecords" class="ag-theme-alpine"></ag-grid-vue>
    </v-card-text>
  </v-card>
</template>

<style lang="scss">
@import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>

<script>
import { AgGridVue } from "ag-grid-vue";
import { AG_GRID_LOCALE_JA } from "../../models/ag-grid/locales";
import { NumericColumn } from "../../models/ag-grid/columnTypes";
import { comma } from "../../filter/NumberFilter";
import { statuses as ApiStatus } from "../../libs/api-client";

export default {
  name: "ForecastDetail",
  props: ["order"],
  components: {
    AgGridVue,
  },
  filters: {
    comma,
  },
  data: () => ({
    gridOptions: {
      defaultColDef: {
        resizable: true,
        sortable: true,
        filter: true,
      },
      columnTypes: {
        dpNumericColumn: NumericColumn,
      },
      columnDefs: [
        {
          field: "orderId",
          headerName: "",
        },
        {
          field: "customerName",
          headerName: "取引先名",
        },
        {
          field: "deliveryName2",
          headerName: "配送先",
        },
        {
          field: "orderQuantity",
          type: "dpNumericColumn",
          headerName: "数量",
        },
      ],
      rowSelection: "single",
      suppressCellSelection: true,
      pagination: true,
      paginationPageSize: 4,
      enableCellTextSelection: true,
      localeText: AG_GRID_LOCALE_JA,
      domLayout: "autoHeight",
    },
    assginRecords: [],
  }),
  beforeMount() {
    this.gridOptions.columnDefs[0].headerName = `${this.screen}ID`;
  },
  mounted() {
    this.init(this.order?.orderId);
  },
  watch: {
    order(value) {
      this.init(value?.orderId);
    },
  },
  computed: {
    screen() {
      return this.isCafereoUser ? "受注" : "発注";
    },
  },
  methods: {
    async init(orderId) {
      try {
        this.loadingOn();
        this.assginRecords = [];
        if (orderId) {
          const response = await this.$store.dispatch("forcast/detail", { orderId: orderId });
          if (ApiStatus.isSystemError(response.data?.header)) {
            return this.redirectError();
          }
          this.assginRecords = response.data.contents.orders;
          this.gridOptions.api.setRowData(this.assginRecords);
          const allColumnIds = this.gridOptions.columnApi.getAllColumns().map((column) => column.colId);
          this.gridOptions.columnApi.autoSizeColumns(allColumnIds);
        }
      } catch (error) {
        console.error("ForecastDetail::init", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
  },
};
</script>
