<template>
  <v-card>
    <v-card-title>
      <span class="headline"><v-icon>mdi-database-edit</v-icon>{{ isCafereoUser ? "受注" : "発注" }}一括編集</span>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-form ref="orderBulkEditForm" lazy-validation>
        <v-container>
          <section-block>
            <v-row v-for="(source, index) in settingSources" :key="index" dense>
              <v-col cols="3">
                <v-select
                  :items="
                    isCafereoUser
                      ? constant.orderCafereoBulkEditColumnsValues
                      : constant.orderVendorBulkEditColumnsValues
                  "
                  label="設定項目"
                  v-model="source.column"
                  :rules="[rules.required, rules.validSelectColumn]"
                  @change="onSettingColSelected(index)"
                  dense
                  filled
                  attach
                ></v-select>
              </v-col>
              <v-col cols="5">
                <v-text-field
                  v-if="
                    source.column === constant.orderCafereoBulkEditColumns.ORDER_QUANTITY ||
                    source.column === constant.orderVendorBulkEditColumns.ORDER_QUANTITY
                  "
                  label="設定値"
                  v-model="source.value"
                  @change="source.value = stringToNumber($event)"
                  :rules="[rules.isNumber, rules.maxLength(8), rules.rangeOfNumber(1)]"
                  filled
                  dense
                >
                </v-text-field>
                <v-text-field
                  v-if="isCafereoUser && source.column === constant.orderCafereoBulkEditColumns.WHOLESALE_RATE"
                  label="設定値"
                  v-model="source.value"
                  @change="source.value = stringToNumber($event)"
                  :rules="[rules.isNumber, rules.isRate, rules.maxRateLength(3, 1), rules.rangeOfNumber(0, 100)]"
                  filled
                  dense
                >
                </v-text-field>
                <delivery-All-field
                  v-if="
                    source.column === constant.orderCafereoBulkEditColumns.SUPPLIER_DELIVERY_CD ||
                    source.column === constant.orderVendorBulkEditColumns.SUPPLIER_DELIVERY_CD
                  "
                  ref="deliveryAll"
                  :groupCodes="deliveryGroupCodes"
                  :code="modDeliveryCode"
                  @selected="onDeliveryAllChanged"
                  disabled-no-data
                ></delivery-All-field>
                <v-checkbox
                  v-if="
                    isCafereoUser && source.column === constant.orderCafereoBulkEditColumns.DIRECT_DELIVERY_REQUEST_FLG
                  "
                  v-model="source.value"
                  label="直送依頼"
                  dense
                ></v-checkbox>
                <v-text-field
                  v-if="
                    isCafereoUser &&
                    (source.column === constant.orderCafereoBulkEditColumns.REMARKS ||
                      source.column === constant.orderCafereoBulkEditColumns.CAFEREO_REMARKS)
                  "
                  label="設定値"
                  v-model="source.value"
                  :rules="[rules.maxLength(100)]"
                  filled
                  dense
                >
                </v-text-field>
                <v-text-field
                  v-if="isCafereoUser && source.column === constant.orderCafereoBulkEditColumns.SLIP_REMARKS"
                  label="設定値"
                  v-model="source.value"
                  :rules="[rules.maxLength(30)]"
                  filled
                  dense
                >
                </v-text-field>
                <v-text-field
                  v-if="
                    source.column === constant.orderCafereoBulkEditColumns.PARTNER_PURCHASE_NO ||
                    source.column === constant.orderVendorBulkEditColumns.PARTNER_PURCHASE_NO
                  "
                  label="設定値"
                  v-model="source.value"
                  :rules="[rules.maxLength(64)]"
                  filled
                  dense
                >
                </v-text-field>
                <v-text-field
                  v-if="
                    source.column === constant.orderCafereoBulkEditColumns.PARTNER_DETAIL_NO ||
                    source.column === constant.orderVendorBulkEditColumns.PARTNER_DETAIL_NO
                  "
                  label="設定値"
                  v-model="source.value"
                  @change="source.value = stringToNumber($event)"
                  :rules="[rules.isNumber, rules.maxLength(6)]"
                  filled
                  dense
                >
                </v-text-field>
                <dp-date-picker
                  v-if="isCafereoUser && source.column === constant.orderCafereoBulkEditColumns.SHIP_PLAN_DATE"
                  type="date"
                  label="設定値"
                  v-model="source.value"
                ></dp-date-picker>
              </v-col>
              <v-col cols="1"
                ><v-btn v-if="index != 0" x-small fab dense @click="onDelSettingSource(index)">
                  <v-icon dark> mdi-minus </v-icon></v-btn
                ></v-col
              >
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <v-btn x-small fab dense @click="onAddSettingSource">
                  <v-icon dark> mdi-plus </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </section-block>
          <v-divider class="mt-3 pt-3"></v-divider>
          <v-row dense>
            <v-col cols="12" sm="12">
              <v-spacer></v-spacer>
              <tooltip-icon-button
                :disabled="selectedRows.length == 0"
                icon="mdi-arrow-down-bold-outline"
                @click="onBtnSetUpdate"
                >一括反映</tooltip-icon-button
              >
            </v-col>
            <v-col cols="12" sm="12" style="height: 450px">
              <ag-grid-vue
                class="ag-theme-alpine"
                :defaultColDef="defaultColDef"
                :columnDefs="columnDefs"
                :alwaysShowHorizontalScroll="true"
                :suppressColumnVirtualisation="true"
                :pagination="true"
                paginationPageSize="10"
                :localeText="localeText"
                rowSelection="multiple"
                @grid-ready="onGridReady"
                @column-everything-changed="onColumnEverythingChanged"
                @selection-changed="onSelectionChanged"
                :columnTypes="columnTypes"
                :getRowNodeId="(data) => data.orderId"
                :frameworkComponents="frameworkComponents"
                @cell-value-changed="onCellValueChanged"
                style="height: 100%"
                @cell-editing-started="onCellEditingStarted"
              >
              </ag-grid-vue>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn color="secondary" @click="onCancelClick">キャンセル</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="onUpdateClick">更新</v-btn>
    </v-card-actions>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
    </v-overlay>
    <error-grid-dialog
      ref="updateErrorGrid"
      width="80%"
      height="80%"
      icon=""
      title="受注一括編集"
      btnSubmit="登録"
      :columns="errorColumns"
    ></error-grid-dialog>
  </v-card>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import { AG_GRID_LOCALE_JA } from "../../models/ag-grid/locales";
import {
  NumericColumn,
  NumericDP2Column,
  PercentColumn,
  DateColumn,
  FullDateColumn,
  CheckmarkColumn,
  EditableColumn,
  EditableDateColumn,
  EditableCheckBoxColumn,
  EditableCustomDateColumn,
} from "../../models/ag-grid/columnTypes";
import { LockedCellRenderer, ProtectCellRenderer } from "../../models/ag-grid/cellRenderers";
import { getColumnDef } from "../../models/ag-grid/helpers";
import TooltipIconButton from "../common/TooltipIconButton.vue";
import SectionBlock from "../common/SectionBlock.vue";
import {
  DateCellEditor,
  CheckboxCellEditor,
  CustomDateCellEditor,
  NumericCellEditor,
} from "../../components/ag-grid/cellEditors";
import ErrorGridDialog from "../../components/common/ErrorGridDialog.vue";
import { statuses as ApiStatus } from "../../libs/api-client";
import Validation from "../../libs/validation";
//import moment from "moment";
import {} from "../../components/ag-grid/cellEditors";
import { BooleanFilter, SelectionFilter } from "../../components/ag-grid/filters";
import { Cafereo, Vendor } from "../../consts/editColumns/OrderBulkEditColumns";
import CafereoOrderStatuses from "../../consts/orderStatus/CafereoOrderStatuses";
import VendorOrderStatuses from "../../consts/orderStatus/VendorOrderStatuses";
import VendorProductStatus from "../../consts/productStatus/VendorProductStatus";
import OrderTypes from "../../consts/OrderTypes";
import ProductTypes from "../../consts/ProductTypes";
import ProductOrderTypes from "../../consts/ProductOrderTypes";
import { DateValueFormatter } from "../../models/ag-grid/valueFormatters";
import CafereoProductStatus from "../../consts/productStatus/CafereoProductStatus";
import DeliveryAllField from "../common/fields/DeliveryAllField.vue";
import { AutocompleteSelectCellEditor } from "ag-grid-autocomplete-editor";
import ConvertUtils from "../../utils/ConvertUtils";
export default {
  name: "OrderBulkEdit",
  props: ["inputModel"],
  components: {
    AgGridVue,
    TooltipIconButton,
    ErrorGridDialog,
    SectionBlock,
    DeliveryAllField,
    /* eslint-disable vue/no-unused-components */
    DateCellEditor,
    CheckboxCellEditor,
    CustomDateCellEditor,
    NumericCellEditor,
  },
  data: function () {
    const checkStatuses = { true: "✔", false: "　" };
    return {
      updateModel: {},
      defaultModel: {
        OrderQuantity: null,
        wholesaleRate: null,
        CompanyStoreCd: null,
        DirectDeliveryRequestFlg: null,
        Remarks: null,
        CafereoRemarks: null,
        SlipRemarks: null,
        PartnerPurchaseNo: null,
        PartnerDetailNo: null,
        ShipmentPlanDate: null,
        Delivery: {},
      },
      constant: {
        orderCafereoBulkEditColumnsValues: Cafereo.all(),
        orderCafereoBulkEditColumns: Cafereo,
        orderVendorBulkEditColumnsValues: Vendor.all(),
        orderVendorBulkEditColumns: Vendor,
      },
      defaultColDef: null,
      columnDefs: null,
      gridApi: null,
      columnApi: null,
      selectedRows: [],
      domLayout: null,
      localeText: AG_GRID_LOCALE_JA,
      updateList: [],
      settingSources: [],
      columnTypes: {
        dpNumericColumn: NumericColumn,
        dpNumericDP2Column: NumericDP2Column,
        dpPercentColumn: PercentColumn,
        dpDateColumn: DateColumn,
        dpFullDateColumn: FullDateColumn,
        dpCheckmarkColumn: CheckmarkColumn,
        dpEditableColumn: EditableColumn,
        dpEditableDateColumn: EditableDateColumn,
        dpEditableCheckBoxColumn: EditableCheckBoxColumn,
        dpEditableCustomDateColumn: EditableCustomDateColumn,
      },
      frameworkComponents: {
        dpDateCellEditor: DateCellEditor,
        dpCheckboxCellEditor: CheckboxCellEditor,
        dpBooleanFilter: BooleanFilter,
        dpSelectionFilter: SelectionFilter,
        dpCellDatePicker: CustomDateCellEditor,
        dpNumericCellEditor: NumericCellEditor,
      },
      errorColumns: [
        { headerName: "受注ID", field: "orderId" },
        {
          headerName: "エラー内容",
          field: "errorMessage",
          wrapText: true,
          autoHeight: true,
          cellRenderer: function (param) {
            return param.data.errorMessage.join("<br>");
          },
        },
      ],
      errorRows: [],
      updateSuccessRecords: [],
      rules: {
        required: Validation.required,
        isNumber: Validation.isNumber,
        maxLength: Validation.maxLength,
        isRate: Validation.isRate,
        maxRateLength: Validation.maxRateLength,
        rangeOfNumber: Validation.rangeOfNumber,
        validSelectColumn: (value) => this.validSelectColumn(value),
      },
      stringToNumber: ConvertUtils.stringToNumber,
      deliveries: [],
      modDeliveryCode: null,
      userCustomerCode: null,
      deliveryGroupCodes: [],
      cafereoColumns: [
        {
          headerName: "",
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          checkboxSelection: true,
          filter: false,
          resizable: false,
          sortable: false,
          pinned: "left",
          cellRenderer: (params) => {
            if (this.activeStatusTab === "unchangeApproved" && !params.data.productLocked) {
              return ProtectCellRenderer({ matcher: "orderLocked" })(params);
            } else {
              return LockedCellRenderer({ matcher: "orderLocked" })(params);
            }
          },
        },
        { headerName: "受注ID", field: "orderId", pinned: "left" },
        { headerName: "指示書NO", field: "directionNumber" },
        {
          headerName: "受注区分",
          field: "orderType",
          filter: "dpSelectionFilter",
          filterParams: { options: OrderTypes.all() },
          valueGetter: (params) => OrderTypes.of(params.data.orderType),
        },
        {
          headerName: "区分",
          field: "productOrderType",
          filter: "dpSelectionFilter",
          filterParams: { options: ProductOrderTypes.all() },
          valueGetter: (params) => ProductOrderTypes.of(params.data.productOrderType),
        },
        {
          headerName: "商品区分",
          field: "productType",
          filter: "dpSelectionFilter",
          filterParams: { options: ProductTypes.all() },
          valueGetter: (params) => ProductTypes.of(params.data.productType),
        },
        {
          headerName: "受注ステータス",
          field: "orderStatus",
          filter: "dpSelectionFilter",
          filterParams: { options: CafereoOrderStatuses.all() },
          valueGetter: (params) => CafereoOrderStatuses.of(params.data.orderStatus),
        },
        {
          headerName: "商品ステータス",
          field: "productStatus",
          filter: "dpSelectionFilter",
          filterParams: { options: CafereoProductStatus.all() },
          valueGetter: (params) => CafereoProductStatus.of(params.data.productStatus),
        },
        { headerName: "フォーキャスト受注", field: "forecastOrderFlg", type: "dpCheckmarkColumn" },
        { headerName: "登録日", field: "orderDate", type: "dpDateColumn" },
        { headerName: "社店CD", field: "customerCode" },
        { headerName: "法人名", field: "corporateName" },
        { headerName: "取引先名", field: "customerName" },
        { headerName: "JANCODE", field: "janCode" },
        { headerName: "タイトル", field: "title" },
        { headerName: "商品名", field: "productName" },
        {
          headerName: "配送先名",
          field: "deliveryName",
          editable: true,
          cellEditor: AutocompleteSelectCellEditor,
          filterValueGetter: (params) => {
            return params.data.deliveryName
              ? String(params.data.deliveryName.label)
              : String(params.data.deliveryName.value.delivery1 + " " + params.data.deliveryName.value.delivery2);
          },
          cellEditorParams: () => {
            return {
              selectData: this.deliveries,
              placeholder: "配送先入力",
              required: true,
              autocomplete: {
                showOnFocus: true,
                fetch: (cellEditor, text, update) => {
                  let match = text || cellEditor.eInput.value;
                  const current = this.deliveries.filter(
                    (c) => c.label.includes(match) || c.deliveryCode.includes(match)
                  );
                  update(current);
                },
              },
            };
          },
          valueFormatter: (params) => params.value?.label || params.value?.text || params?.value || "",
          comparator: (valueA, valueB, nodeA, nodeB) => {
            const valueALbel = valueA ? valueA.label : nodeA.data.deliveryName1 + " " + nodeA.data.deliveryName2;
            const valueBLbel = valueB ? valueB.label : nodeB.data.deliveryName1 + " " + nodeB.data.deliveryName2;
            if (valueALbel == valueBLbel) return 0;
            return valueALbel > valueBLbel ? 1 : -1;
          },
          cellStyle: (params) => {
            let ret = { backgroundColor: "#FFFFCC" };
            if (params.data.updateDeliveryName) {
              ret.backgroundColor = "#CCFFCC";
            }
            return ret;
          },
        },
        {
          headerName: "直送依頼",
          field: "directDeliveryRequestFlg",

          filter: "dpBooleanFilter",
          filterParams: { caption: checkStatuses },
          editable: true,
          cellEditor: "agSelectCellEditor",
          cellEditorParams: {
            values: Object.keys(checkStatuses),
            cellEditorPopup: true,
          },
          refData: checkStatuses,
          cellStyle: (params) => {
            let ret = { backgroundColor: "#FFFFCC" };
            if (params.data.updateDirectDeliveryRequestFlg) {
              ret.backgroundColor = "#CCFFCC";
            }
            return ret;
          },
        },
        { headerName: "発売日", field: "releaseDate" },
        {
          headerName: "Box入数",
          field: "inBoxQuantity",
          type: "dpNumericColumn",
        },
        {
          headerName: "CT中Box入数",
          field: "inCtBoxQuantity",
          type: "dpNumericColumn",
        },
        {
          headerName: "CT中pcs入数",
          field: "inCtPcsQuantity",
          type: "dpNumericColumn",
        },
        {
          headerName: "CTsizeW",
          field: "ctSizeWidth",
          type: "dpNumericColumn",
        },
        {
          headerName: "CTsizeD",
          field: "ctSizeDepth",
          type: "dpNumericColumn",
        },
        {
          headerName: "CTsizeH",
          field: "ctSizeHeight",
          type: "dpNumericColumn",
        },
        { headerName: "入荷予定日", field: "arrivalPlanDate" },
        {
          headerName: "出荷予定日",
          field: "shipmentPlanDate",
          filter: "agDateColumnFilter",
          type: "dpEditableCustomDateColumn",
          cellEditorFramework: "CustomDateCellEditor",
          valueFormatter: DateValueFormatter("YYYY/MM/DD"),
          cellStyle: (params) => {
            let ret = { backgroundColor: "#FFFFCC" };
            if (params.data.updateShipmentPlanDate) {
              ret.backgroundColor = "#CCFFCC";
            }
            return ret;
          },
        },
        { headerName: "受注単位", field: "orderUnit", type: "dpNumericColumn" },
        {
          headerName: "受注数",
          field: "orderQuantity",
          type: "dpNumericColumn",
          cellEditor: "dpNumericCellEditor",
          editable: true,
          cellStyle: (params) => {
            let ret = { backgroundColor: "#FFFFCC" };
            if (params.data.updateOrderQuantity) {
              ret.backgroundColor = "#CCFFCC";
            }
            return ret;
          },
        },
        { headerName: "上代", field: "retailPrice", type: "dpNumericColumn" },
        {
          headerName: "卸掛率",
          field: "wholesaleRate",
          type: "dpPercentColumn",
          cellEditor: "dpNumericCellEditor",
          editable: true,
          cellStyle: (params) => {
            let ret = { backgroundColor: "#FFFFCC" };
            if (params.data.updateWholesaleRate) {
              ret.backgroundColor = "#CCFFCC";
            }
            return ret;
          },
        },
        {
          headerName: "卸単価",
          field: "unitPrice",
          type: "dpNumericColumn",
        },
        { headerName: "単価合計金額", field: "unitPriceTotal", type: "dpNumericColumn" },
        { headerName: "メーカー名", field: "supplierName" },
        {
          headerName: "仕入掛率",
          field: "purchaseRate",
          type: "dpPercentColumn",
          valueGetter: (params) => params.data.purchaseRate.toFixed(1),
        },
        {
          headerName: "仕入単価",
          field: "purchasePrice",
          type: "dpNumericColumn",
        },
        { headerName: "粗利単価", field: "grossProfit", type: "dpNumericColumn" },
        { headerName: "粗利合計金額", field: "grossProfitTotal", type: "dpNumericColumn" },
        { headerName: "注残数", field: "backOrderQuantity", type: "dpNumericColumn" },
        { headerName: "配送CD", field: "deliveryCode" },
        { headerName: "配送先住所", field: "deliveryAddress" },
        { headerName: "届先法人名", field: "deliveryName1" },
        { headerName: "届先名称", field: "deliveryName2" },
        { headerName: "確保倉庫", field: "insureStorageName" },
        { headerName: "伝票番号", field: "slipNumber" },
        { headerName: "親受注ID", field: "parentOrderId" },
        { headerName: "フォーキャスト割当分", field: "assignedForecastFlg", type: "dpCheckmarkColumn" },
        { headerName: "一括取込番号", field: "importNumber" },
        { headerName: "担当チーム", field: "salesTeam" },
        { headerName: "営業担当", field: "salesStaff" },
        { headerName: "アシスタント", field: "salesAssistant" },
        {
          headerName: "備考",
          field: "remarks",
          editable: true,
          cellStyle: (params) => {
            let ret = { backgroundColor: "#FFFFCC" };
            if (params.data.updateRemarks) {
              ret.backgroundColor = "#CCFFCC";
            }
            return ret;
          },
        },
        {
          headerName: "社内備考",
          field: "cafereoRemarks",
          editable: true,
          cellStyle: (params) => {
            let ret = { backgroundColor: "#FFFFCC" };
            if (params.data.updateCafereoRemarks) {
              ret.backgroundColor = "#CCFFCC";
            }
            return ret;
          },
        },
        {
          headerName: "伝票備考",
          field: "slipRemarks",
          editable: true,
          cellStyle: (params) => {
            let ret = { backgroundColor: "#FFFFCC" };
            if (params.data.updateSlipRemarks) {
              ret.backgroundColor = "#CCFFCC";
            }
            return ret;
          },
        },
        {
          headerName: "取引先専用番号",
          field: "partnerPurchaseNo",
          editable: true,
          cellStyle: (params) => {
            let ret = { backgroundColor: "#FFFFCC" };
            if (params.data.updatePartnerPurchaseNo) {
              ret.backgroundColor = "#CCFFCC";
            }
            return ret;
          },
        },
        {
          headerName: "取引先専用明細番号",
          field: "partnerDetailNo",
          editable: true,
          cellStyle: (params) => {
            let ret = { backgroundColor: "#FFFFCC" };
            if (params.data.updatePartnerDetailNo) {
              ret.backgroundColor = "#CCFFCC";
            }
            return ret;
          },
        },

        { headerName: "PO番号", field: "poNumber" },
        { headerName: "ハイコン", field: "hycon", type: "dpDateColumn" },
        { headerName: "法人CD", field: "corporateCode", hide: true },
        { headerName: "発注締日", field: "orderCloseDate", hide: true },
        { headerName: "案件番号", field: "propositionNumber", hide: true },
        { headerName: "承認者", field: "approvalUser", hide: true },
        { headerName: "承認日時", field: "approvalDatetime", hide: true },
        { headerName: "掛率訂正承認者", field: "rateCorrectionAuthorizer", hide: true },
        { headerName: "掛率訂正承認日時", field: "rateCorrectionApprovalDate", hide: true },
        { headerName: "販売店承認者", field: "salesAuthorizer", hide: true },
        { headerName: "販売店承認日時", field: "salesApprovalDate", hide: true },
        { headerName: "納品予定日", field: "deliveryPlanDate", hide: true },
        { headerName: "伝票日付", field: "slipDate", hide: true },
        { headerName: "登録者", field: "createUser", hide: true },
        { headerName: "登録日時", field: "createDatetime", hide: true },
        { headerName: "更新者", field: "updateUser", hide: true },
        { headerName: "更新日時", field: "updateDatetime", hide: true },
        { headerName: "発注ID", field: "purchaseId", hide: true },
      ],
      vendorColumns: [
        {
          headerName: "",
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          checkboxSelection: true,
          filter: false,
          resizable: false,
          sortable: false,
          pinned: "left",
          cellRenderer: (params) => {
            if (this.activeStatusTab === "unchangeApproved" && !params.data.productLocked) {
              return ProtectCellRenderer({ matcher: "orderLocked" })(params);
            } else {
              return LockedCellRenderer({ matcher: "orderLocked" })(params);
            }
          },
        },
        { headerName: "発注ID", field: "orderId", pinned: "left" },
        { headerName: "発注日", field: "orderDate", type: "dpDateColumn" },
        {
          headerName: "発注ステータス",
          field: "orderStatus",
          filter: "dpSelectionFilter",
          filterParams: { options: VendorOrderStatuses.all() },
          valueGetter: (params) => VendorOrderStatuses.of(params.data.orderStatus),
        },
        {
          headerName: "商品ステータス",
          field: "productStatus",
          filter: "dpSelectionFilter",
          filterParams: { options: VendorProductStatus.all() },
          valueGetter: (params) => VendorProductStatus.of(params.data.productStatus),
        },
        {
          headerName: "発注区分",
          field: "orderType",
          filter: "dpSelectionFilter",
          filterParams: { options: OrderTypes.all() },
          valueGetter: (params) => OrderTypes.of(params.data.orderType),
        },
        { headerName: "発売元", field: "supplierName" },
        { headerName: "販売元", field: "makerName" },
        { headerName: "JANCODE", field: "janCode" },
        { headerName: "タイトル", field: "title" },
        { headerName: "商品名", field: "productName" },
        { headerName: "上代", field: "retailPrice", type: "dpNumericColumn" },
        {
          headerName: "卸掛率",
          field: "wholesaleRate",
          type: "dpPercentColumn",
          valueGetter: (params) => params.data.wholesaleRate.toFixed(1),
        },
        {
          headerName: "発注数",
          field: "orderQuantity",
          type: "dpNumericColumn",
          editable: true,
          cellStyle: (params) => {
            let ret = { backgroundColor: "#FFFFCC" };
            if (params.data.updateOrderQuantity) {
              ret.backgroundColor = "#CCFFCC";
            }
            return ret;
          },
        },
        {
          headerName: "卸単価",
          field: "unitPrice",
          type: "dpNumericDP2Column",
        },
        { headerName: "卸単価合計", field: "unitPriceTotal", type: "dpNumericColumn" },
        { headerName: "発売日", field: "releaseDate" },
        {
          headerName: "出荷予定日",
          field: "shipmentPlanDate",
          type: "dpDateColumn",
          filter: "agDateColumnFilter",
        },
        {
          headerName: "納品予定日",
          field: "deliveryPlanDate",
          type: "dpDateColumn",
          filter: "agDateColumnFilter",
        },
        { headerName: "直送依頼", field: "directDeliveryRequestFlg", type: "dpCheckmarkColumn" },
        {
          headerName: "配送先名",
          field: "deliveryName",
          editable: true,
          cellEditor: AutocompleteSelectCellEditor,
          filterValueGetter: (params) => {
            return params.data.deliveryName
              ? String(params.data.deliveryName.label)
              : String(params.data.deliveryName.value.delivery1 + " " + params.data.deliveryName.value.delivery2);
          },
          cellEditorParams: () => {
            return {
              selectData: this.deliveries,
              placeholder: "配送先入力",
              required: true,
              autocomplete: {
                showOnFocus: true,
                fetch: (cellEditor, text, update) => {
                  let match = text || cellEditor.eInput.value;
                  const current = this.deliveries.filter(
                    (c) => c.label.includes(match) || c.deliveryCode.includes(match)
                  );
                  update(current);
                },
              },
            };
          },
          valueFormatter: (params) => params.value?.label || params.value?.text || params?.value || "",
          comparator: (valueA, valueB, nodeA, nodeB) => {
            const valueALbel = valueA ? valueA.label : nodeA.data.deliveryName1 + " " + nodeA.data.deliveryName2;
            const valueBLbel = valueB ? valueB.label : nodeB.data.deliveryName1 + " " + nodeB.data.deliveryName2;
            if (valueALbel == valueBLbel) return 0;
            return valueALbel > valueBLbel ? 1 : -1;
          },
          cellStyle: (params) => {
            let ret = { backgroundColor: "#FFFFCC" };
            if (params.data.updateDeliveryName) {
              ret.backgroundColor = "#CCFFCC";
            }
            return ret;
          },
        },
        { headerName: "配送CD", field: "deliveryCode", hide: true },
        { headerName: "配送先住所", field: "deliveryAddress", hide: true },
        { headerName: "届先法人名", field: "deliveryName1" },
        { headerName: "届先名称", field: "deliveryName2" },
        { headerName: "案件番号", field: "propositionNumber" },
        {
          headerName: "取引先専用番号",
          field: "partnerPurchaseNo",
          editable: true,
          cellStyle: (params) => {
            let ret = { backgroundColor: "#FFFFCC" };
            if (params.data.updatePartnerPurchaseNo) {
              ret.backgroundColor = "#CCFFCC";
            }
            return ret;
          },
        },
        {
          headerName: "取引先専用明細番号",
          field: "partnerDetailNo",
          editable: true,
          cellStyle: (params) => {
            let ret = { backgroundColor: "#FFFFCC" };
            if (params.data.updatePartnerDetailNo) {
              ret.backgroundColor = "#CCFFCC";
            }
            return ret;
          },
        },
        { headerName: "法人CD", field: "corporateCode", hide: true },
        { headerName: "発注締日", field: "orderCloseDate", hide: true },
        { headerName: "案件番号", field: "propositionNumber", hide: true },
        { headerName: "承認者", field: "approvalUser", hide: true },
        { headerName: "承認日時", field: "approvalDatetime", hide: true },
        { headerName: "掛率訂正承認者", field: "rateCorrectionAuthorizer", hide: true },
        { headerName: "掛率訂正承認日時", field: "rateCorrectionApprovalDate", hide: true },
        { headerName: "販売店承認者", field: "salesAuthorizer", hide: true },
        { headerName: "販売店承認日時", field: "salesApprovalDate", hide: true },
        { headerName: "納品予定日", field: "deliveryPlanDate", hide: true },
        { headerName: "伝票日付", field: "slipDate", hide: true },
        { headerName: "登録者", field: "createUser", hide: true },
        { headerName: "登録日時", field: "createDatetime", hide: true },
        { headerName: "更新者", field: "updateUser", hide: true },
        { headerName: "更新日時", field: "updateDatetime", hide: true },
        { headerName: "受注ID", field: "orderId", hide: true },
      ],
    };
  },
  watch: {
    inputModel(inputModel) {
      this.init(inputModel);
    },
  },
  computed: {
    isLoading() {
      return this.$store.getters["ui/isLoading"];
    },
  },
  methods: {
    // ****************************
    // ag-grid定義
    // ****************************
    setColumnDefs() {
      let columnDefs = [];
      if (this.isCafereoUser) {
        columnDefs = columnDefs.concat(this.cafereoColumns);
        // ag-grid内のvalidation等をセット
        getColumnDef(columnDefs, "wholesaleRate").valueSetter = this.valueSetter4WholesaleRate;
        getColumnDef(columnDefs, "directDeliveryRequestFlg").valueSetter = this.valueSetter4DirectDeliveryRequestFlg;
      } else {
        columnDefs = columnDefs.concat(this.vendorColumns);
      }
      // ag-grid内のvalidation等をセット
      getColumnDef(columnDefs, "orderQuantity").valueSetter = this.valueSetter4OrderQuantity;
      getColumnDef(columnDefs, "partnerDetailNo").valueSetter = this.valueSetter4PartnerDetailNo;
      return columnDefs;
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;

      this.init(this.inputModel);

      var allColumnIds = [];
      this.columnApi.getAllColumns().forEach(function (column) {
        allColumnIds.push(column.colId);
      });
      this.columnApi.autoSizeColumns(allColumnIds);
      this.gridApi.refreshCells();
    },
    onColumnEverythingChanged() {
      var allColumnIds = [];
      this.columnApi?.getAllColumns().forEach(function (column) {
        allColumnIds.push(column.colId);
      });
      this.columnApi?.autoSizeColumns(allColumnIds);
      this.gridApi?.refreshCells();
    },
    onSelectionChanged() {
      this.selectedRows = this.gridApi.getSelectedRows();
    },
    onCellEditingStarted(event) {
      const popupTop = event.api.getCellEditorInstances()[0].getGui().style.top;
      event.api.getCellEditorInstances()[0].getGui().style.top = parseInt(popupTop) - 40 + "px";
    },
    valueSetter4OrderQuantity(params) {
      let isNumber = Validation.isNumber(params.newValue);
      if (isNumber !== true) {
        this.$dialog.notify.error((this.isCafereoUser ? "受注数は" : "発注数は") + isNumber, { timeout: 2300 });
        return false;
      }
      if (Number(params.newValue) < 1) {
        this.$dialog.notify.error((this.isCafereoUser ? "受注数は" : "発注数は") + "1以上で入力してください。", {
          timeout: 2300,
        });
        return false;
      }
      // カフェレオユーザかつ出荷確定、出荷済み、キャンセルの場合変更不可
      if (
        this.isCafereoUser &&
        [CafereoOrderStatuses.SECURED, CafereoOrderStatuses.SHIPPED, CafereoOrderStatuses.CANCELED].includes(
          params.data.orderStatus
        )
      ) {
        this.$dialog.notify.error("出荷確定、出荷済みまたはキャンセルのため、受注数は変更できません。", {
          timeout: 2300,
        });
        return false;
      }
      params.data.orderQuantity = params.newValue;
      params.data.updateOrderQuantity = true;
      return true;
    },
    valueSetter4WholesaleRate(params) {
      let isNumber = Validation.isRate(params.newValue);
      if (isNumber !== true) {
        this.$dialog.notify.error("卸掛率は" + isNumber, { timeout: 2300 });
        return false;
      }
      if (Number(params.newValue) < 0 || Number(params.newValue) > 100) {
        this.$dialog.notify.error("卸掛率は0～100の値を設定してください。", { timeout: 2300 });
        return false;
      }
      if (Number(params.data.unitPrice) === 0 && params.data.orderType !== OrderTypes.PURCHASE) {
        this.$dialog.notify.error("買切り以外で商品単価が0のため卸掛率は変更できません。", { timeout: 2300 });
        return false;
      }
      params.data.wholesaleRate = params.newValue;
      params.data.updateWholesaleRate = true;
      return true;
    },
    valueSetter4DirectDeliveryRequestFlg(params) {
      // カフェレオ向け受注ステータスが「受注済み」、「受注締め」、「発注済み」、「入荷待ち」以外
      // またはカフェレオ向け発注ステータスが入荷待ち:7以降の場合、変更できない
      if (
        [CafereoOrderStatuses.SECURED, CafereoOrderStatuses.SHIPPED, CafereoOrderStatuses.CANCELED].includes(
          params.data.orderStatus
        ) ||
        (params.data.purchaseStatus != null && params.data.purchaseStatus > 6)
      ) {
        this.$dialog.notify.error(
          "受注ステータスが出荷確定、出荷済み、キャンセルのいづれかとなっているか、出荷ステータスが入荷待ち以降のため、直送フラグは変更できません。",
          {
            timeout: 2300,
          }
        );
        return false;
      }
      params.data.directDeliveryRequestFlg = params.newValue;
      params.data.updateDirectDeliveryRequestFlg = true;
      return true;
    },
    valueSetter4PartnerDetailNo(params) {
      let isNumber = Validation.isNumber(params.newValue);
      if (isNumber !== true) {
        this.$dialog.notify.error("取引先専用明細番号は" + isNumber, { timeout: 2300 });
        return false;
      }
      params.data.partnerDetailNo = params.newValue;
      params.data.updatePartnerDetailNo = true;
      return true;
    },
    // ****************************
    // 画面アクション定義
    // ****************************
    async init(inputModel) {
      this.updateModel = this.defaultModel;
      this.gridApi.setRowData(JSON.parse(JSON.stringify(inputModel)));

      this.updateSuccessRecords = [];
      // 初期表示時、設定項目を１つ表示する。
      this.onAddSettingSource();
      if (this.$refs.orderBulkEditForm) {
        this.$refs.orderBulkEditForm.resetValidation();
      }
      // 配送先の設定
      this.setDeliveryName();
    },
    reset() {
      this.init(this.inputModel);
      this.updateSuccessRecords = [];
    },
    onCancelClick() {
      this.$emit("onDialogClose", this.updateSuccessRecords);
    },
    async onUpdateClick() {
      var requestRecords = [];
      this.errorRows = [];
      var isValid = true;
      this.gridApi.forEachNode((record) => {
        var error = this.validateRow(record.data);
        if (error != true) {
          this.errorRows.push({
            orderId: record.data.orderId,
            errorMessage: error,
          });
          isValid = false;
        } else {
          requestRecords.push(this.requestFormat(record.data));
        }
      });
      if (isValid) {
        try {
          this.loadingOn();
          var param = {
            rows: requestRecords,
            splitNum: 100,
          };
          const response = await this.$store.dispatch("order/bulkupdate", param);
          let error = response.data?.header;
          // 一覧更新レコード
          let updateRows = [];
          this.errorRows = [];
          const orderName = this.isCafereoUser ? `受注` : `発注`;
          switch (error.resultCode) {
            case ApiStatus.consts.SUCCESS:
            case ApiStatus.consts.BUSINESS_ERROR:
            case ApiStatus.consts.ALREADY_CHANGED:
              // エラーメッセージ格納
              if (error.messages) {
                Object.keys(error.messages).forEach((key) => {
                  this.errorRows.push({
                    orderId: key,
                    errorMessage: error.messages[key],
                  });
                });
              }
              // 一覧部の更新分のレコード設定
              if (response.data.contents) {
                this.gridApi.forEachNode((row) => {
                  response.data.contents.orders.forEach((order) => {
                    let isError = this.errorRows.some((r) => r.orderId === row.data.orderId);
                    if (order.orderId == row.data.orderId && !isError) {
                      this.updateSuccessRecords.push(order);
                      updateRows.push(order);
                    }
                  });
                });
              }
              // エラー表示
              if (this.errorRows.length > 0) {
                // 確認ダイアログから更新した分を削除(エラーがある場合のみ)

                this.gridApi.applyTransaction({ remove: updateRows });
                this.$refs.updateErrorGrid.open({ records: this.errorRows });
                this.$dialog.notify.error(orderName + `情報の更新処理に失敗したデータが存在します。ご確認ください。`, {
                  timeout: 2300,
                });
              } else {
                this.$dialog.notify.info(orderName + `情報を更新しました`, { timeout: 2300 });
                this.$refs.updateErrorGrid.close();
                this.$emit("onEditSubmit", this.updateSuccessRecords);
                this.reset();
              }
              break;
            default:
              this.redirectError();
              break;
          }
        } catch (error) {
          console.error("OrderBulkEdit_update::onSubmit", error);
          this.apiRequestError(error);
        } finally {
          this.loadingOff();
        }
      } else {
        this.$refs.updateErrorGrid.open({ records: this.errorRows });
        this.$dialog.notify.error(`更新データに入力エラーが存在します。ご確認ください。`, {
          timeout: 2300,
        });
      }
    },
    requestFormat(row) {
      row.unitPrice = String(row.unitPrice);
      row.retailPrice = String(row.retailPrice);
      row.deliveryCompany = String(row.deliveryCompany);
      row.orderQuantity = Number(row.orderQuantity);
      row.wholesaleRate = Number(row.wholesaleRate);
      row.directDeliveryRequestFlg = this.booleanFormat(row.directDeliveryRequestFlg);
      row.partnerDetailNo = row.partnerDetailNo ? Number(row.partnerDetailNo) : null;
      row.lastUpdateDatetime = row.updateDatetime;
      return row;
    },
    onCellValueChanged(params) {
      // セルの色を変えるため項目更新をチェック
      if (params.colDef.field == "orderQuantity") {
        params.data.updateOrderQuantity = true;
        this.onQuantityChanged(params.data);
      } else if (params.colDef.field == "wholesaleRate") {
        params.data.updateWholesaleRate = true;
        this.onWholesaleRateChanged(params.data);
      } else if (params.colDef.field == "CompanyStoreCd") {
        params.data.updateCompanyStoreCd = true;
      } else if (params.colDef.field == "deliveryName") {
        params.data.updateDeliveryName = true;
        params.data.deliveryCode = params.value?.value?.deliveryCode;
        params.data.deliveryPostalCode = params.value?.value?.deliveryZipCode;
        params.data.deliveryAddress = params.value?.value?.deliveryAddress;
        params.data.deliveryName1 = params.value?.value?.delivery1;
        params.data.deliveryName2 = params.value?.value?.delivery2;
        params.data.updateDeliveryName = true;
      } else if (params.colDef.field == "directDeliveryRequestFlg") {
        params.data.updateDirectDeliveryRequestFlg = true;
      } else if (params.colDef.field == "remarks") {
        params.data.updateRemarks = true;
      } else if (params.colDef.field == "cafereoRemarks") {
        params.data.updateCafereoRemarks = true;
      } else if (params.colDef.field == "slipRemarks") {
        params.data.updateSlipRemarks = true;
      } else if (params.colDef.field == "partnerPurchaseNo") {
        params.data.updatePartnerPurchaseNo = true;
      } else if (params.colDef.field == "partnerDetailNo") {
        params.data.updatePartnerDetailNo = true;
      } else if (params.colDef.field == "shipmentPlanDate") {
        params.data.updateShipmentPlanDate = true;
      }
      let rows = [];
      rows.push(params.node);
      this.gridApi.refreshCells({ force: true, rowNodes: rows });
    },
    onSettingColSelected(index) {
      this.settingSources[index].value = "";
    },
    onAddSettingSource() {
      let settingSource = {
        column: 0,
        value: "",
      };
      this.settingSources.push(settingSource);
    },
    onDelSettingSource(index) {
      this.settingSources.splice(index, 1);
    },
    onBtnSetUpdate() {
      this.loadingOn();
      if (this.validate()) {
        this.selectedRows.forEach((row) => {
          this.settingSources.forEach((source) => {
            if (
              source.column === this.constant.orderCafereoBulkEditColumns.ORDER_QUANTITY ||
              source.column === this.constant.orderVendorBulkEditColumns.ORDER_QUANTITY
            ) {
              // 受注数
              // 販売店(カフェレオ以外)またはカフェレオ向け受注ステータスが「受注済み」、「受注締め」、「発注済み」、「入荷待ち」のいづれかの場合、反映可能
              if (
                !this.isCafereoUser ||
                ![CafereoOrderStatuses.SECURED, CafereoOrderStatuses.SHIPPED, CafereoOrderStatuses.CANCELED].includes(
                  row.orderStatus
                )
              ) {
                row.orderQuantity = source.value;
                row.updateOrderQuantity = true;
                this.onQuantityChanged(row);
              }
            } else if (source.column === this.constant.orderCafereoBulkEditColumns.WHOLESALE_RATE) {
              // 卸掛率
              if (row.orderType !== OrderTypes.PURCHASE && row.unitPrice === 0) {
                // dummy
              } else {
                row.wholesaleRate = source.value;
                row.updateWholesaleRate = true;
                this.onWholesaleRateChanged(row);
              }
            } else if (
              source.column === this.constant.orderCafereoBulkEditColumns.SUPPLIER_DELIVERY_CD ||
              source.column === this.constant.orderVendorBulkEditColumns.SUPPLIER_DELIVERY_CD
            ) {
              // 配送先名
              if (this.Delivery) {
                row.deliveryName = {
                  value: this.Delivery,
                  text: this.Delivery.delivery2,
                  deliveryCode: this.Delivery.deliveryCode,
                  label: this.Delivery.delivery1 + " " + this.Delivery.delivery2,
                };
                row.deliveryCode = this.Delivery.deliveryCode;
                row.deliveryPostalCode = this.Delivery.deliveryZipCode;
                row.deliveryAddress = this.Delivery.deliveryAddress;
                row.deliveryName1 = this.Delivery.delivery1;
                row.deliveryName2 = this.Delivery.delivery2;
                row.updateDeliveryName = true;
              }
            } else if (source.column === this.constant.orderCafereoBulkEditColumns.DIRECT_DELIVERY_REQUEST_FLG) {
              // 直送依頼フラグ
              // カフェレオ向け受注ステータスが「受注済み」、「受注締め」、「発注済み」、「入荷待ち」のいづれか
              // またはカフェレオ向け発注ステータスが納品予約済み未満(定数化されたら修正)の場合、反映可能
              if (
                ![CafereoOrderStatuses.SECURED, CafereoOrderStatuses.SHIPPED, CafereoOrderStatuses.CANCELED].includes(
                  row.orderStatus
                ) ||
                (row.purchaseStatus != null && row.purchaseStatus <= 6)
              ) {
                row.directDeliveryRequestFlg = source.value;
                row.updateDirectDeliveryRequestFlg = true;
              }
            } else if (source.column === this.constant.orderCafereoBulkEditColumns.REMARKS) {
              // 備考
              row.remarks = source.value;
              row.updateRemarks = true;
            } else if (source.column === this.constant.orderCafereoBulkEditColumns.CAFEREO_REMARKS) {
              // 社内備考
              row.cafereoRemarks = source.value;
              row.updateCafereoRemarks = true;
            } else if (source.column === this.constant.orderCafereoBulkEditColumns.SLIP_REMARKS) {
              // 伝票備考
              row.slipRemarks = source.value;
              row.updateSlipRemarks = true;
            } else if (
              source.column === this.constant.orderCafereoBulkEditColumns.PARTNER_PURCHASE_NO ||
              source.column === this.constant.orderVendorBulkEditColumns.PARTNER_PURCHASE_NO
            ) {
              // 取引先専用番号
              row.partnerPurchaseNo = source.value;
              row.updatePartnerPurchaseNo = true;
            } else if (
              source.column === this.constant.orderCafereoBulkEditColumns.PARTNER_DETAIL_NO ||
              source.column === this.constant.orderVendorBulkEditColumns.PARTNER_DETAIL_NO
            ) {
              // 取引先専用明細番号
              row.partnerDetailNo = source.value;
              row.updatePartnerDetailNo = true;
            } else if (source.column === this.constant.orderCafereoBulkEditColumns.SHIP_PLAN_DATE) {
              // 出荷予定日
              row.shipmentPlanDate = source.value;
              row.updateShipmentPlanDate = true;
            }
          });
        });
        this.gridApi.applyTransaction({ update: this.selectedRows });
        this.updateModel = this.defaultModel;
      }
      this.loadingOff();
    },
    // ****************************
    // 共通業務処理
    // ****************************
    onQuantityChanged(row) {
      row.unitPriceTotal = row.unitPrice * row.orderQuantity;
      row.lowerPriceTotal = row.purchasePrice * row.orderQuantity;
      row.grossProfit = row.unitPrice - row.purchasePrice;
      row.grossProfitTotal = row.unitPriceTotal - row.lowerPriceTotal;
    },
    onWholesaleRateChanged(row) {
      row.unitPrice = Math.round(row.retailPrice * (row.wholesaleRate / 100));
      this.onQuantityChanged(row);
    },
    // ****************************
    // Validation定義
    // ****************************
    validate() {
      const isValid = this.$refs.orderBulkEditForm.validate();
      if (!isValid) {
        this.$dialog.error({ text: "入力エラーがあります", actions: ["OK"] });
      }
      return isValid;
    },
    validateRow(row) {
      var ret = true;
      const orderQuantityName = this.isCafereoUser ? "受注数" : "発注数";
      var messages = [];
      // 受注数
      this.setValidMessage(this.rules.required(row.orderQuantity), orderQuantityName, messages);
      this.setValidMessage(this.rules.isNumber(row.orderQuantity), orderQuantityName, messages);
      this.setValidMessage(this.rules.maxLength(8)(row.orderQuantity), orderQuantityName, messages);
      this.setValidMessage(this.rules.rangeOfNumber(1)(row.orderQuantity), orderQuantityName, messages);
      // 卸掛率
      this.setValidMessage(this.rules.required(row.wholesaleRate), "卸掛率", messages);
      this.setValidMessage(this.rules.isRate(row.wholesaleRate), "卸掛率", messages);
      this.setValidMessage(this.rules.maxRateLength(3, 1)(row.wholesaleRate), "卸掛率", messages);
      this.setValidMessage(this.rules.rangeOfNumber(0, 100)(row.purchaseQuantity), "卸掛率", messages);
      // 備考
      this.setValidMessage(this.rules.maxLength(100)(row.remarks), "備考", messages);
      // 社内備考
      this.setValidMessage(this.rules.maxLength(100)(row.cafereoRemarks), "社内備考", messages);
      // 伝票備考
      this.setValidMessage(this.rules.maxLength(30)(row.slipRemarks), "伝票備考", messages);
      // 取引先専用番号
      this.setValidMessage(this.rules.maxLength(64)(row.partnerPurchaseNo), "取引先専用番号", messages);
      // 取引先専用明細番号
      this.setValidMessage(this.rules.isNumber(row.partnerDetailNo), "取引先専用明細番号", messages);
      this.setValidMessage(this.rules.maxLength(6)(row.partnerDetailNo), "取引先専用明細番号", messages);

      if (messages.length > 0) ret = messages;
      return ret;
    },
    validSelectColumn(value) {
      if (value == null) return true;
      if (this.settingSources.filter((settingSource) => settingSource.column === value).length > 1)
        return "同一項目が選択されています。";
      return true;
    },
    setValidMessage(check, columnName, messages) {
      if (!(check === true)) messages.push(columnName + "は" + check);
    },
    booleanFormat(value) {
      if (value == null || value == "") return false;
      if (value == "Y") return true;
      if (value == "N") return false;
      return Boolean(value);
    },
    async setDeliveryList(customerCode) {
      try {
        const response = await this.$store.dispatch("deliveryDestination/searchDelivery", {
          groupCode: customerCode,
        });
        if (!ApiStatus.isSystemError(response.data?.header)) {
          var deliveryList = response.data.contents.deliveries;
          for (let i = 0; i < deliveryList.length; i++) {
            const delivery = deliveryList[i];
            if (!this.deliveries.some((val) => val.value.deliveryCode == delivery.deliveryCode)) {
              this.deliveries.push({
                value: delivery,
                text: delivery.delivery2,
                deliveryCode: delivery.deliveryCode,
                label: delivery.delivery1 + " " + delivery.delivery2,
              });
            }
          }
        }
      } catch (error) {
        console.error("OrderSplit::setDeliveryList", error);
        this.apiRequestError(error);
      }
    },
    getDeliveryList() {
      for (const row of this.deliveryGroupCodes) {
        if (row) {
          this.setDeliveryList(row, false);
        }
      }
    },
    setDeliveryName() {
      this.gridApi.forEachNode((node) => {
        const delivery = this.deliveries.find((row) => row.deliveryCode === node.data.deliveryCode);

        if (delivery) {
          node.data.deliveryName = delivery;
        } else {
          node.data.deliveryName = {
            value: null,
            text: node.data.deliveryName2,
            deliveryCode: node.data.deliveryCode,
            label: node.data.deliveryName1 + " " + node.data.deliveryName2,
          };
        }
      });
    },
    onDeliveryAllChanged(value) {
      const delivery = this.$refs.deliveryAll[0]?.selected();
      if (delivery) {
        this.Delivery = delivery;
        this.Delivery.deliveryCode = value;
      } else {
        this.Delivery = null;
      }
    },
    createDeliveryNameValue(value, valueObject) {
      return {
        value: valueObject,
        text: value?.deliveryName2,
        deliveryCode: value?.deliveryCode,
        label: value?.deliveryName1 + " " + value?.deliveryName2,
      };
    },
  },

  beforeMount() {
    // 配送先向けgroupcodeリストの作成
    this.inputModel.forEach((row) => {
      if (!this.deliveryGroupCodes.includes(row.customerCode)) {
        this.deliveryGroupCodes.push(row.customerCode);
      }
    });
    this.getDeliveryList();

    this.domLayout = "autoHeight";
    this.defaultColDef = {
      filter: "agTextColumnFilter",
      resizable: true,
      sortable: true,
      suppressSizeToFit: true,
      filterParams: {
        newRowsAction: "keep",
      },
      maxWidth: 300,
    };
    // カラム設定
    this.columnDefs = this.setColumnDefs();
  },
};
</script>
