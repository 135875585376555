var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"absolute":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"icon":"","small":"","disabled":_vm.disabled}},'v-btn',attrs,false),on),[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticStyle:{"transition":"all 0.1s 0s linear"},attrs:{"size":!_vm.nonZoom && hover ? 30 : 15}},'v-icon',attrs,false),on),[_vm._v(_vm._s(_vm.icon))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.text))])])],1)]}}],null,true)})]}}])},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }